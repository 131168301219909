import { produce } from 'immer'

import * as constants from './constants'

export const initialState = {
  membershipProducts: null,
  membershipBanners: null,
  membershipLink: null,
  membershipError: '',
  isSubmitting: false,
  membershipInfo: null,
}

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      // MEMBERSHIP PRODUCTS
      case constants.MEMBERSHIP_PRODUCTS:
        break
      case constants.MEMBERSHIP_PRODUCTS_SUCCESS:
        newState.membershipProducts = action.payload
        break
      case constants.MEMBERSHIP_PRODUCTS_FAILED:
        break

      // MEMBERSHIP PRODUCTS
      case constants.LOAD_MEMBERSHIP_BANNER:
        break
      case constants.LOAD_MEMBERSHIP_BANNER_SUCCESS:
        newState.membershipBanners = action.payload
        break
      case constants.LOAD_MEMBERSHIP_BANNER_FAILED:
        break

      // APPLY_MEMBERSHIP
      case constants.APPLY_MEMBERSHIP:
        break
      case constants.APPLY_MEMBERSHIP_SUCCESS:
        break
      case constants.APPLY_MEMBERSHIP_FAILED:
        break

      // VERIFY_MEMBERSHIP
      case constants.VERIFY_MEMBERSHIP:
        break
      case constants.VERIFY_MEMBERSHIP_SUCCESS:
        break
      case constants.VERIFY_MEMBERSHIP_FAILED:
        break

      // RENEW_MEMBERSHIP
      case constants.RENEW_MEMBERSHIP:
        break
      case constants.RENEW_MEMBERSHIP_SUCCESS:
        break
      case constants.RENEW_MEMBERSHIP_FAILED:
        break

      // LINK_MEMBERSHIP
      case constants.LINK_MEMBERSHIP:
        newState.membershipLink = null
        newState.isSubmitting = true
        break
      case constants.LINK_MEMBERSHIP_SUCCESS:
        newState.membershipLink = action.payload
        newState.membershipError = ''
        newState.isSubmitting = false
        break
      case constants.LINK_MEMBERSHIP_FAILED:
        newState.membershipLink = null
        newState.membershipError = action.error
        newState.isSubmitting = false
        break
      case constants.SET_MEMBERSHIP_SUBMITTING:
        newState.isSubmitting = action.payload
        break
      case constants.RESET_LINK_MEMBERSHIP_FORM:
        newState.membershipLink = null
        newState.membershipError = ''
        newState.isSubmitting = false
        break
      case constants.VERIFY_PRIMARY_MEMBERSHIP:
        break
      case constants.VERIFY_PRIMARY_MEMBERSHIP_SUCCESS:
        break
      case constants.VERIFY_PRIMARY_MEMBERSHIP_FAILED:
        break
      case constants.SET_MEMBERSHIP_INFO:
        newState.membershipInfo = action.payload
        break
      default:
        break
    }
  })

export default reducer
