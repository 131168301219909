import * as constants from './constants'
import { produce } from 'immer'

export const initialState = {
  vouchers: {
    items: {},
    hasMoreItems: false,
    isLoadingInitial: false,
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      // GET VOUCHERS LIST
      case constants.GET_VOUCHERS:
        if (!!action.params.initialLoading) {
          newState.vouchers.isLoadingInitial = true
          newState.vouchers.items = {}
        }
        break
      case constants.GET_VOUCHERS_SUCCESS:
        newState.vouchers.items[action.payload.assigned_page] =
          action.payload.items
        newState.vouchers.hasMoreItems = action.payload.hasMoreItems
        newState.vouchers.isLoadingInitial = false
        break
      case constants.GET_VOUCHERS_FAILED:
        newState.vouchers.isLoadingInitial = false
        break

      // SAVE CLAIMED VOUCHERS
      case constants.SAVE_CLAIM_VOUCHER:
        break
      case constants.SAVE_CLAIM_VOUCHER_SUCCESS:
        newState.vouchers.items[action.payload.assigned_page].find(
          (v) => v.uniqueId === action.payload.uniqueId
        ).is_claimed = action.payload.is_claimed
        break

      case constants.SAVE_CLAIM_VOUCHER_FAILED:
        newState.vouchers.items[action.payload.assigned_page].find(
          (v) => v.uniqueId === action.payload.uniqueId
        ).error = action.payload.error
        break

      case constants.SET_VOUCHER_IS_PROCESSING:
        newState.vouchers.items[action.payload.assigned_page].find(
          (v) => v.uniqueId === action.payload.uniqueId
        ).is_processing = action.payload.is_processing
        break

      case constants.SET_VOUCHER_AS_EXPIRED:
        newState.vouchers.items[action.payload.assigned_page].find(
          (v) => v.uniqueId === action.payload.uniqueId
        ).is_expired = true

      default:
        break
    }
  })

export default reducer
