import { call, put, takeEvery } from 'redux-saga/effects'
import dayjs from 'dayjs'
import config from 'global-config'
import request from 'utils/request'
import { showError, showSuccess } from 'utils/notification'
import * as constants from './constants'
import * as voucherActions from './actions'

function* onGetVouchers(action) {
  const page = action.params?.page || 1
  const page_size = action?.params?.page_size || 200
  const coupon_category = action?.params?.coupon_category || ''
  const claimed = action?.params?.claimed || 0

  try {
    let requestURL = `${config.apiUrl}/coupons/list?current_page=${page}&page_size=${page_size}`

    if (coupon_category !== '') {
      requestURL = requestURL + `&coupon_category=${coupon_category}`
    }

    if (claimed === 1) {
      requestURL = requestURL + `&claimed=1`
    }

    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    const defaultTime = '23:59:59' // No settings yet set hours on backend 23:59:59

    yield put(
      voucherActions.getVouchersSuccess({
        assigned_page: page,
        items: res
          .filter((item) => item.coupon_amount !== 0)
          .map((voucher) => {
            const toDate = voucher.to_date
              ? `${dayjs(voucher.to_date).format('YYYY-MM-DD')} ${defaultTime}`
              : null

            return {
              ...voucher,
              uniqueId: voucher.code ?? voucher.rule_id, // main vouchers dont have rule id, addons dont have voucher code when not yet claimed
              to_date: toDate,
              is_expired: toDate ? dayjs(toDate).isBefore(dayjs()) : false,
              is_stackable: !!voucher.is_stackable,
              is_collectible: !!voucher.is_collectible,
              is_claimed: !!voucher.is_claimed,
              assigned_page: page,
              coupon_tags: voucher.coupon_tags
                ? voucher.coupon_tags.split(',').filter(Boolean)
                : [],
              is_processing: false,
            }
          }),
        hasMoreItems: res.length < page_size ? false : true,
      })
    )
  } catch (err) {
    showError(`Failed to fetch list of vouchers!`)
    yield put(voucherActions.getVouchersFailed(err))
  }
}

function* onSaveClaimVoucher(action) {
  const voucher = action.payload.voucher

  try {
    const requestURL = `${config.apiUrl}/coupons/save`

    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({ rule_id: voucher.rule_id }),
    })

    if (!res) {
      throw `failed claim voucher`
    }

    yield put(
      voucherActions.saveClaimedVoucherSuccess({
        ...voucher,
        is_claimed: true,
      })
    )

    yield showSuccess(`Successfully claimed voucher: ${voucher.rule_name}`)
  } catch (err) {
    yield put(
      voucherActions.saveClaimedVoucherFailed({
        ...voucher,
        error: err,
      })
    )

    yield showError(`Failed to claim voucher: ${voucher.rule_name}`)
  } finally {
    yield put(
      voucherActions.setVoucherIsProcessing({
        ...voucher,
        is_processing: false,
      })
    )
  }
}

export default function* accountSaga() {
  yield takeEvery(constants.GET_VOUCHERS, onGetVouchers)
  yield takeEvery(constants.SAVE_CLAIM_VOUCHER, onSaveClaimVoucher)
}
