import 'intersection-observer'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/browser'
import { PHP_TIMEZONE_CODE } from 'global-constant'
import dayjs from 'dayjs'

import { store, persistor, history } from './configureStore'
import LandersApp from 'containers/Landers/Injectable'
import LoaderIndicator from 'components/LoaderIndicator'
import { initFirebase } from 'utils/firebase'
import * as serviceWorker from './serviceWorker'
import config from 'global-config'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './asset/font/fontawesome/css/all.css'
import 'react-spring-bottom-sheet/dist/style.css'
import './index.css'

const utc = require('dayjs/plugin/utc')
const isToday = require('dayjs/plugin/isToday')
const duration = require('dayjs/plugin/duration')
const timezone = require('dayjs/plugin/timezone')
const calendar = require('dayjs/plugin/calendar')
const isBetween = require('dayjs/plugin/isBetween')
const isTomorrow = require('dayjs/plugin/isTomorrow')
const relativeTime = require('dayjs/plugin/relativeTime')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const advancedFormat = require('dayjs/plugin/advancedFormat')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(utc)
dayjs.extend(isToday)
dayjs.extend(calendar)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(isTomorrow)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

dayjs.tz.setDefault(PHP_TIMEZONE_CODE)

Sentry.init({
  dsn: config.sentryDSN,
})

initFirebase()

TagManager.initialize({
  gtmId: config.gtmContainerId,
})

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<LoaderIndicator />} persistor={persistor}>
      <ConnectedRouter history={history}>
        <LandersApp />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
