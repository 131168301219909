export const ACTION_TYPE = {
  APPLY: 'apply',
  CLAIM: 'claim',
  USE_NOW: 'useNow',
  UNAPPLY: 'unapply',
}

export const ACTION_LABEL = {
  [ACTION_TYPE.APPLY]: 'APPLY NOW',
  [ACTION_TYPE.CLAIM]: 'CLAIM',
  [ACTION_TYPE.USE_NOW]: 'USE NOW',
}

export const VOUCHER_CATEGORY = {
  MAIN: 'main',
  ADDONS: 'addons',
}

export const VOUCHER_LABELS_DEFAULT = {
  [VOUCHER_CATEGORY.MAIN]: {
    category: {
      title: 'Main Voucher',
      note: '*Choose one only',
    },
  },
  [VOUCHER_CATEGORY.ADDONS]: {
    category: {
      title: 'Add-on Voucher',
      note: '*Can choose multiple',
    },
  },
}

export const VOUCHER_DEFAULT_LOGO_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
}

// GET VOUCHERS
export const GET_VOUCHERS = 'landers/Vouchers/GET_VOUCHERS'
export const GET_VOUCHERS_SUCCESS = 'landers/Vouchers/GET_VOUCHERS_SUCCESS'
export const GET_VOUCHERS_FAILED = 'landers/Vouchers/GET_VOUCHERS_FAILED'

// SAVE CLAIMED VOUCHERS
export const SAVE_CLAIM_VOUCHER = 'landers/Vouchers/SAVE_CLAIM_VOUCHER'
export const SAVE_CLAIM_VOUCHER_SUCCESS =
  'landers/Vouchers/SAVE_CLAIM_VOUCHER_SUCCESS'
export const SAVE_CLAIM_VOUCHER_FAILED =
  'landers/Vouchers/SAVE_CLAIM_VOUCHER_FAILED'

export const SET_VOUCHER_IS_PROCESSING =
  'landers/Vouchers/SET_VOUCHER_IS_PROCESSING'

export const SET_VOUCHER_AS_EXPIRED = 'landers/Vouchers/SET_VOUCHER_AS_EXPIRED'
