export function submitPaymentForm(path, params) {
  const form = document.createElement('form')
  form.method = 'post'
  form.action = path

  Object.entries(params || {}).forEach((param) => {
    const [key, val] = param
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = key
    hiddenField.value = val
    form.appendChild(hiddenField)
  })

  document.body.appendChild(form)
  form.submit()
}

export function submitLoginForm(path, params) {
  const form = document.createElement('form')
  form.method = 'get'
  form.action = path

  Object.entries(params || {}).forEach((param) => {
    const [key, val] = param
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = key
    hiddenField.value = val
    form.appendChild(hiddenField)
  })

  document.body.appendChild(form)
  form.submit()
}
