import * as constant from './constants'

// COLLECTIBLE VOUCHERS
export function getVouchers(params) {
  return {
    type: constant.GET_VOUCHERS,
    params,
  }
}

export function getVouchersSuccess(payload) {
  return {
    type: constant.GET_VOUCHERS_SUCCESS,
    payload,
  }
}

export function getVouchersFailed(payload) {
  return {
    type: constant.GET_VOUCHERS_FAILED,
    payload,
  }
}

export function saveClaimedVoucher(payload) {
  return {
    type: constant.SAVE_CLAIM_VOUCHER,
    payload,
  }
}

export function saveClaimedVoucherSuccess(payload) {
  return {
    type: constant.SAVE_CLAIM_VOUCHER_SUCCESS,
    payload,
  }
}

export function saveClaimedVoucherFailed(payload) {
  return {
    type: constant.SAVE_CLAIM_VOUCHER_FAILED,
    payload,
  }
}

// VOUCHER PROCESSING LOADER
export function setVoucherIsProcessing(payload) {
  return {
    type: constant.SET_VOUCHER_IS_PROCESSING,
    payload,
  }
}

// SET VOUCHER AS EXPIRED
export function setVoucherAsExpired(payload) {
  return {
    type: constant.SET_VOUCHER_AS_EXPIRED,
    payload,
  }
}
