import config from 'global-config'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { get, map } from 'lodash'
import axios from 'axios'
import request, { getAccessToken, getStoreCode } from 'utils/request'

import * as actions from './actions'
import * as constants from './constants'
import { makeSelectCurrentLocation } from 'containers/Landers/selectors'
import qs from 'qs'
import { getInStockValue } from 'utils/product'
import { getStoreViewUrl } from 'utils/request'

// call request create cart guest
function* onGetShoppingNow(action) {
  try {
    const cartMaskId = get(action, 'payload.cartMaskId')
    let requestURL = ''
    if (getAccessToken()) {
      requestURL = `${config.apiUrl}/ldminicart/mine/didyouforgetit`
    } else {
      if (cartMaskId) {
        requestURL = `${config.apiUrl}/ldminicart/guest/didyouforgetit/${cartMaskId}`
      } else {
        console.error('cartMaskId and access token null! ')
      }
    }
    const productsShoppingNow = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.getShoppingNowSuccess(productsShoppingNow))
  } catch (err) {
    yield put(actions.getShoppingNowFailed(err))
  }
}

function* onGetProductRecommendation1() {
  const recommendation1$ = yield call(axios.get, constants.RECOMMENDATION1_URL)
  const recommendation1 = recommendation1$.data || []

  const filterGroups = []

  recommendation1.forEach((product) => {
    filterGroups.push({
      field: 'sku',
      value: product.sku,
    })
  })

  const params = {
    searchCriteria: {
      filterGroups: [
        {
          filters: filterGroups,
        },
      ],
    },
  }

  const requestURL = `${getStoreViewUrl()}/products?${qs.stringify(params, {
    encode: false,
  })}`

  const products = yield call(request, requestURL, {
    method: 'GET',
  })

  yield put(actions.getProductRecommendation1Success(products.items))
}

function* onGetProductRecommendation2() {
  const recommendation2$ = yield call(axios.get, constants.RECOMMENDATION2_URL)
  const recommendation2 = recommendation2$.data || []

  const filterGroups = []

  recommendation2.forEach((product) => {
    filterGroups.push({
      field: 'sku',
      value: product.sku,
    })
  })

  const params = {
    searchCriteria: {
      filterGroups: [
        {
          filters: filterGroups,
        },
      ],
    },
  }

  const requestURL = `${getStoreViewUrl()}/products?${qs.stringify(params, {
    encode: false,
  })}`

  const products = yield call(request, requestURL, {
    method: 'GET',
  })

  const filteredProducts = products.items.filter(
    (item) => getInStockValue(item) > 0
  )

  yield put(actions.getProductRecommendation2Success(filteredProducts))
}

// call request create cart guest
function* onGetRecommendProducts(action) {
  try {
    const cartMaskId = get(action, 'payload.cartMaskId')
    let requestURL = ''
    if (getAccessToken()) {
      requestURL = `${config.apiUrl}/ldminicart/mine/recommendproduct`
    } else {
      if (cartMaskId) {
        requestURL = `${config.apiUrl}/ldminicart/guest/recommendproduct/${cartMaskId}`
      } else {
        console.error('cartMaskId and access token null! ')
      }
    }

    const productsRecommendProducts = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.getRecommendProductsSuccess(productsRecommendProducts))
  } catch (err) {
    yield put(actions.getRecommendProductsFailed(err))
  }
}

// call request create cart guest
function* onGetProgressBar(action) {
  const townshipIdStorePickup = action.params.townshipIdStorePickup || ''
  let requestURL = `${config.apiUrl}/progress-bar`
  const currentLocation = yield select((state) =>
    makeSelectCurrentLocation()(state)
  )
  let townshipId =
    get(currentLocation, 'extension_attributes.township_id') ||
    get(currentLocation, 'barangay.id') ||
    get(currentLocation, 'datastore.township_id')

  if (townshipIdStorePickup) {
    townshipId = townshipIdStorePickup
  }
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      sourcecode: getStoreCode() || '',
      townshipid: townshipId || '',
    }
    const res = yield call(axios, requestURL, {
      method: 'GET',
      headers,
    })
    const data = res.data || {}

    data.shipping_fee_limit = map(data.shipping_fee_limit, (limit) => ({
      shipping_fee: +limit.shipping_fee,
      total: +limit.total,
    }))

    data.vouchers_limit = map(data.vouchers_limit, (limit) => ({
      number_gained: +limit.number_gained,
      total: +limit.total,
    }))
    yield put(actions.getProgressBarSuccess(data))
  } catch (err) {
    yield put(actions.getProgressBarFailed(err))
  }
}

export default function* checkoutCartSaga() {
  yield takeLatest(constants.GET_SHOPPING_NOW, onGetShoppingNow)
  yield takeLatest(constants.GET_PROGRESSBAR, onGetProgressBar)
  yield takeLatest(constants.GET_RECOMMEND_PRODUCT, onGetRecommendProducts)
  yield takeLatest(
    constants.GET_PRODUCT_RECOMMENDATION_1,
    onGetProductRecommendation1
  )
  yield takeLatest(
    constants.GET_PRODUCT_RECOMMENDATION_2,
    onGetProductRecommendation2
  )
}
