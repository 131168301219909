import * as checkoutActions from 'containers/Checkout/actions'
import * as checkoutSelectors from 'containers/Checkout/selectors'
import * as globalActions from 'containers/Landers/actions'
import * as modalActions from 'containers/Modals/actions'
import config from 'global-config'
import { get, isEmpty, set } from 'lodash'
import { call, put, select, takeLatest, delay } from 'redux-saga/effects'
import { showError } from 'utils/notification'
import request, { getAccessToken } from 'utils/request'
import dayjs from 'dayjs'

import * as actions from './actions'
import * as constants from './constants'
import { MEMBERSHIP_CARD_TYPE } from 'global-constant'
import { trackEvent, trackingEvent } from 'utils/firebase'
import { MEMBERSHIP_CARD_TYPES } from 'containers/Modals/screens/MembershipApply/constants'
import replace from 'lodash/replace'

const __DEV__ = process.env.NODE_ENV !== 'production'

function* onLoadMembershipProducts() {
  const requestURL = `${config.apiUrl}/membership-products`
  yield put(globalActions.showLoader())

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(actions.loadMembershipProductsSuccess(res))
    yield put(globalActions.hideLoader())
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }
    yield put(globalActions.hideLoader())
    yield put(actions.loadMembershipProductsFailed(err))
  }
}

function* onRenewMembership(action) {
  const requestURL = `${config.apiUrl}/carts/mine/items`
  yield put(globalActions.showLoader())

  yield put(globalActions.showLoader())

  const cartId = yield select((state) =>
    checkoutSelectors.makeSelectCartId()(state)
  )

  const {
    renew_price,
    code,
    name,
    trigger_link_for_renew,
    is_expired_renew,
  } = action.requestData

  const membershipInfo = {
    type: 'renew',
    card_info: {
      code: code,
      name: name,
    },
  }
  const membershipRequest = {
    cart_item: {
      quote_id: cartId,
      sku: action.requestData.package,
      product_type: 'customer_membership',
      qty: 1,
      extension_attributes: {
        custom_price: renew_price,
        membership_info: JSON.stringify(membershipInfo),
      },
    },
  }

  if (trigger_link_for_renew) {
    set(membershipRequest, 'membershipInfo.card_info.trigger_link_for_renew', 1)
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(membershipRequest),
    })
    yield put(modalActions.hideMembershipModal())
    yield put(actions.renewMembershipSuccess(res))
    yield put(checkoutActions.setCartAsNeedSync('membership'))
    if (!is_expired_renew) {
      yield put(checkoutActions.syncProductThenGoCheckout())
    }

    yield put(globalActions.hideLoader())
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }
    yield put(globalActions.hideLoader())
    yield put(actions.renewMembershipFailed(err))
  }
}

function* onLoadMembershipBanners() {
  const requestURL = `${config.apiUrl}/landers-bannerslider-byname/membership_page_top_slider`
  yield put(globalActions.showLoader())

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadMembershipBannerSuccess(res))
    yield put(globalActions.hideLoader())
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }
    yield put(globalActions.hideLoader())
    yield put(actions.loadMembershipBannerFailed(err))
  }
}

function* onAppyMembership(action) {
  const getCartMaskId = (state) =>
    checkoutSelectors.makeSelectCartMaskId()(state)
  const cartMaskId = yield select(getCartMaskId)

  let requestURL = `${config.apiUrl}/guest-carts/${cartMaskId}/items`

  if (getAccessToken()) {
    requestURL = `${config.apiUrl}/carts/mine/items`
  }

  yield put(globalActions.showLoader())

  const cartId = yield select((state) =>
    checkoutSelectors.makeSelectCartId()(state)
  )

  const membershipRequest = {
    cart_item: {
      quote_id: cartId,
      ...action.requestData,
    },
  }

  if (__DEV__) {
    console.log(membershipRequest)
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(membershipRequest),
    })
    yield put(globalActions.hideLoader())
    yield put(actions.applyMembershipSuccess(res))
    yield put(checkoutActions.setCartAsNeedSync('membership'))
    yield put(checkoutActions.syncProductThenGoCheckout())
    yield put(modalActions.hideMembershipModal())
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }
    showError('Add new membership to cart failed')
    yield put(modalActions.hideMembershipModal())
    yield put(globalActions.hideLoader())
    yield put(actions.applyMembershipFailed(err))
  }
}

function* onVerifyMembership(action) {
  const {
    card_number,
    name,
    card_type, // this is not the real card type it should be action type (extend, renew)
    trigger_link_for_renew,
  } = action.requestData
  const requestURL = `${config.apiUrl}/verify-membership/${card_type}`

  yield put(globalActions.showLoader())

  const cartId = yield select((state) =>
    checkoutSelectors.makeSelectCartId()(state)
  )

  const membershipRequest = {
    cartId: cartId,
    membership_card: {
      card_number,
      name,
    },
  }

  if (trigger_link_for_renew) {
    set(membershipRequest, 'membership_card.trigger_link_for_renew', 1)
  }

  if (__DEV__) {
    console.log(membershipRequest)
  }

  try {
    const validateMembership = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(membershipRequest),
    })

    const {
      extended_members,
      membership_info,
      extend_times_remaining,
      message,
    } = validateMembership

    // Verify for extension card
    if (card_type === 'extend') {
      if (get(membership_info, 'code')) {
        const cardType =
          membership_info.type === 'PREMIUM'
            ? MEMBERSHIP_CARD_TYPES.Premium
            : MEMBERSHIP_CARD_TYPES.Business

        yield put(
          modalActions.showMembershipModal({
            isExtend: true,
            isPremium:
              membership_info.package === MEMBERSHIP_CARD_TYPE.PREMIUM_TYPE,
            ...membership_info,
            timesRemaining: extend_times_remaining,
            extendedMembers: extended_members,
            cardType,
          })
        )
      } else {
        showError(message)
      }
    }

    // Verify for renew card
    if (card_type === 'renew') {
      if (get(membership_info, 'code')) {
        yield put(
          actions.renewMembership({
            ...membership_info,
            trigger_link_for_renew,
          })
        )
      } else {
        showError(validateMembership.message)
      }
    }
    yield put(globalActions.hideLoader())
    yield put(actions.verifyMembershipSuccess(validateMembership))
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }
    yield put(globalActions.hideLoader())
    yield put(actions.verifyMembershipFailed(err))
  }
}

function* onGetMembershipInfo(action) {
  const requestURL = `${config.apiUrl}/membership/mine`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.setMembershipInfo(res))
  } catch (err) {
    yield put(globalActions.hideLoader())
  }
}

function* onLinkMembership(action) {
  const { card_number, name, card_type } = action.requestData
  let requestURL = `${config.apiUrl}/${card_type}-membership/mine`

  yield put(globalActions.showLoader())

  const membershipRequest = {
    membership_card: {
      card_number: replace(card_number, /-/g, ''),
      name,
    },
  }

  if (__DEV__) {
    console.log(membershipRequest)
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(membershipRequest),
    })

    if (res.error) {
      trackEvent(trackingEvent.linkMembership, {
        link_membership_status: 'failed',
      })

      const showChat = get(res, 'message', '').includes(
        'issue was encountered while linking your Membership Card to your account'
      )
      yield put(modalActions.showSupportMessage(showChat))
      yield put(actions.setLinkMembershipSubmitting(false))

      if (showChat === false) {
        yield put(actions.linkMembershipFailed(res.message))
      }
    } else {
      if (res.is_expired) {
        yield put(
          globalActions.getUserInfo({
            membershipRequest: {
              renew_price: '',
              code: '',
              name: '',
              is_expired_renew: true,
            },
          })
        )
        yield put(actions.linkMembershipSuccess(res))
      } else {
        trackEvent(trackingEvent.linkMembership, {
          link_membership_status: 'successful',
        })
        // yield put(globalActions.getUserInfo());
        yield put(actions.linkMembershipSuccess(res))
      }
      // TODO: update later, this logic is not good
      if (card_type !== 'unlink') {
        yield put(globalActions.showLoader())
        yield delay(1500)
        yield put(checkoutActions.getCartInfo())
      }
    }

    yield put(globalActions.hideLoader())
    if (!res.error) {
      yield delay(1000)
      window.location.reload()
    }
  } catch (err) {
    if (__DEV__) {
      console.log(err.json())
    }

    const errorJson = yield err.json()
    const errorMessage = errorJson.message.replace(
      '%code',
      card_number.toString()
    )
    yield put(actions.linkMembershipFailed(errorMessage))
    yield put(globalActions.hideLoader())
  }
}

function* onVerifyPrimaryMembership(action) {
  const { payload } = action
  const { cardType } = payload

  const requestURL = `${config.apiUrl}/verify-membership/${cardType}`
  const membershipRequest = {
    cartId: 0,
    membership_card: {
      card_number: '',
      name: '',
      email: '',
    },
  }
  yield put(globalActions.showLoader())
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(membershipRequest),
    })

    if (!isEmpty(res?.primary_membership_info)) {
      yield put(
        modalActions.showNewMCExpiredModal({
          ...payload,
          primary_membership_info: res?.primary_membership_info,
        })
      )
    } else {
      yield put(modalActions.showNewMCExpiredModal({ ...payload }))
    }

    yield put(globalActions.hideLoader())
    yield put(actions.verifyPrimaryMembershipSuccess(res))
  } catch (err) {
    yield put(globalActions.hideLoader())
    yield put(actions.verifyPrimaryMembershipFailed(err))
  }
}

function* onGetMembershipExpiry({ payload }) {
  const requestURL = `${config.apiUrl}/membership-expiry-prompt`
  const response = yield call(request, requestURL, {
    method: 'GET',
  })

  const expiredDate =
    payload?.extension_attributes?.membership_info?.expired_date

  if (
    payload?.extension_attributes?.is_membership_expired === true &&
    response.expired_prompt === 1
  ) {
    yield put(
      modalActions.setMCExpiredTooltip({
        isShow: true,
        type: constants.EXPIRED_NUDGE,
        expiredDate,
      })
    )

    return
  }

  if (response.about_to_expire_prompt === 1) {
    const numberOfDaysRemaining = dayjs(expiredDate).diff(dayjs(), 'day')

    yield put(
      modalActions.setMCExpiredTooltip({
        isShow:
          numberOfDaysRemaining <= response.prior_days_to_notify ? true : false,
        type: constants.ABOUT_TO_EXPIRE_NUDGE,
        numberOfDays: numberOfDaysRemaining < 0 ? 0 : numberOfDaysRemaining,
        expiredDate,
      })
    )
  }
}

export default function* membershipSaga() {
  yield takeLatest(constants.APPLY_MEMBERSHIP, onAppyMembership)
  yield takeLatest(constants.MEMBERSHIP_PRODUCTS, onLoadMembershipProducts)
  yield takeLatest(constants.LOAD_MEMBERSHIP_BANNER, onLoadMembershipBanners)
  yield takeLatest(constants.VERIFY_MEMBERSHIP, onVerifyMembership)
  yield takeLatest(constants.RENEW_MEMBERSHIP, onRenewMembership)
  yield takeLatest(constants.LINK_MEMBERSHIP, onLinkMembership)
  yield takeLatest(constants.GET_MEMBERSHIP_INFO, onGetMembershipInfo)
  yield takeLatest(
    constants.VERIFY_PRIMARY_MEMBERSHIP,
    onVerifyPrimaryMembership
  )
  yield takeLatest(constants.MEMBERSHIP_EXPIRATION, onGetMembershipExpiry)
}
