import { produce } from 'immer'
import cartHelper from 'containers/Checkout/helpers/cart'
import { get, set, map } from 'lodash'
import localStorage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { getAccessToken } from 'utils/request'
import * as authenticationConstant from 'containers/Authentication/constants'

import * as constants from './constants'
import * as checkoutCartConstants from 'containers/Checkout/screens/CheckoutCart/constants'

const mapItemIdToCart = (prevItems, nextItems) => {
  return prevItems.map((currentItem) => {
    if (
      currentItem.product_type === 'customer_membership' ||
      currentItem.item_id ||
      ['buyxgety', 'bundle_group'].includes(
        get(currentItem, 'promotion.promotion_type')
      )
    ) {
      return currentItem
    }
    const obj = nextItems.find(({ sku }) => currentItem.sku === sku)
    return obj ? { ...currentItem, item_id: obj.item_id } : currentItem
  })
}
const persistConfig = {
  key: 'checkout',
  storage: localStorage,
  whitelist: [
    'cartId',
    'cartMaskId',
    'selectedTimeslot',
    'shippingAddress',
    'cartNeedSync',
    'orderId',
    'cancellationOption',
  ],
  debounce: 3000,
}

export const initialState = {
  newUpdatedQtys: null,
  oldUpdatedQtys: null,
  cartData: {
    items: [],
  },
  cartItemsCount: '',
  cartTotals: {},
  neededMergedItems: null,
  invalidCartItems: null,
  cartMaskId: '',
  cartId: null,
  shippingMethods: [],
  paymentMethods: [],
  mediaPath: '',
  deliveryTimeslots: [],
  selectedTimeslot: null,
  selectedPaymentMethod: null,
  storePickupAddress: null,
  progressBar: null,
  shippingAddress: null,
  orderId: 0,
  shoppingNow: null,
  recommendProducts: [],
  subscribeProduct: null,
  createdOrder: null,
  vouchersApplied: [],
  vouchersStatusCheck: {},
  showApplyVoucherModalOnLoad: false,
  cartNeedSync: false,
  cancellationOption: '',
  defaultCancellationOption: '',
  feeTooltipVisible: false,
  isRestoreCartProcessing: false,
  lastOrderOOS: '',
  isSubmittedPayment: false,
  storesTimeSlots: {},
  selectedPaymentCard: null,
  paymentCards: [],
  showSlowCartLoading: false,
  productRecommendations1: [],
  productRecommendations2: [],
}

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case constants.CREATE_CART:
        break
      case constants.CREATE_CART_SUCCESS:
        if (getAccessToken()) {
          newState.cartId = action.payload
        } else {
          newState.cartMaskId = action.payload
        }

        break
      case constants.CREATE_CART_FAILED:
        break

      case constants.GET_CART_INFO:
        break
      case constants.GET_CART_INFO_SUCCESS:
        newState.cartId = action.payload.id
        newState.cartData = { ...action.payload }
        break
      case constants.GET_CART_INFO_FAILED:
        break

      case constants.GET_CART_TOTALS:
        newState.cartTotals.isLoading = true
        break
      case constants.GET_CART_TOTALS_SUCCESS:
        newState.cartTotals = action.payload

        newState.vouchersApplied = get(
          action,
          'payload.extension_attributes.coupon_codes',
          []
        )

        newState.cartTotals.isLoading = false
        break
      case constants.GET_CART_TOTALS_FAILED:
        newState.cartTotals.isLoading = false
        break
      case constants.SET_LOADING_CART_TOTALS:
        newState.cartTotals.isLoading = action.payload
        break

      case constants.VALIDATE_CART_ITEMS:
        break
      case constants.VALIDATE_CART_ITEMS_SUCCESS:
        newState.invalidCartItems = action.payload
        break
      case constants.VALIDATE_CART_ITEMS_FAILED:
        break

      case constants.SYNC_CART_SERVER:
        break
      case constants.SYNC_CART_SERVER_SUCCESS:
        const cartItems = state.cartData.items || []
        const cartPromoItems = state.cartData.promotion_items || []
        newState.cartData.items = mapItemIdToCart(cartItems, action.payload)
        newState.cartData.promotion_items = mapItemIdToCart(
          cartPromoItems,
          action.payload
        )
        break
      case constants.SYNC_CART_SERVER_FAILED:
        break

      case constants.GET_CART_RULES:
        break
      case constants.GET_CART_RULES_SUCCESS: {
        newState.cartData = { ...action.payload }
        newState.cartItemsCount = get(action, 'payload.items_qty', 0)
        break
      }
      case constants.GET_CART_RULES_FAILED:
        break

      case constants.CLEAR_CART_RULES: {
        newState.cartData = initialState.cartData
        newState.cartItemsCount = 0
        break
      }

      case constants.GET_DELIVERY_TIMESLOTS:
        break
      case constants.GET_DELIVERY_TIMESLOTS_SUCCESS:
        newState.selectedPaymentMethod = null
        newState.deliveryTimeslots = action.payload
        break
      case constants.GET_DELIVERY_TIMESLOTS_FAILED:
        break

      case constants.SELECT_DELIVERY_TIMESLOT:
        newState.selectedTimeslot = action.timeslot
        break

      case constants.VALIDATE_DELIVERY_TIMESLOT:
        break
      case constants.VALIDATE_DELIVERY_TIMESLOT_SUCCESS:
        break
      case constants.VALIDATE_DELIVERY_TIMESLOT_FAILED:
        break

      case constants.GET_SHIPPING_METHODS:
        break
      case constants.GET_SHIPPING_METHODS_SUCCESS:
        break
      case constants.GET_SHIPPING_METHODS_FAILED:
        break

      case constants.ESTIMATE_SHIPPING_METHODS:
        break
      case constants.ESTIMATE_SHIPPING_METHODS_SUCCESS:
        break
      case constants.ESTIMATE_SHIPPING_METHODS_FAILED:
        break

      case constants.SET_SHIPPING_ADDRESS:
        newState.shippingAddress = action.address
        break

      case constants.UPDATE_SHIPPING_ADDRESS:
        break
      case constants.UPDATE_SHIPPING_ADDRESS_SUCCESS:
        break
      case constants.UPDATE_SHIPPING_ADDRESS_FAILED:
        break

      case constants.SET_PAYMENT_METHOD:
        newState.selectedPaymentMethod = action.method
        break

      case constants.UPDATE_PAYMENT_INFORMATION:
        break
      case constants.UPDATE_PAYMENT_INFORMATION_SUCCESS:
        newState.orderId = action.payload
        newState.cartMaskId = ''
        newState.cartId = ''
        break
      case constants.UPDATE_PAYMENT_INFORMATION_FAILED:
        newState.orderId = 0
        break

      case constants.GET_ORDER_BYID:
        break
      case constants.GET_ORDER_BYID_SUCCESS:
        newState.paymentMethods = []
        newState.createdOrder = action.payload
        break
      case constants.GET_ORDER_BYID_FAILED:
        newState.createdOrder = {}
        break
      case constants.CLEAR_ORDER_ID:
        newState.orderId = 0
        break

      case constants.GET_PAYMENT_METHODS:
        break
      case constants.GET_PAYMENT_METHODS_SUCCESS:
        newState.paymentMethods = action.payload
        break
      case constants.GET_PAYMENT_METHODS_FAILED:
        break
      case constants.GET_MEDIA_PATH_SUCCESS:
        newState.mediaPath = action.payload
        break

      case constants.APPLY_VOUCHER:
        break
      case constants.APPLY_COUPON_SUCCESS:
        newState.couponCode = action.payload.couponCode
        break
      case constants.APPLY_VOUCHER_SUCCESS:
        newState.vouchersStatusCheck = action.payload.vouchersStatusCheck
        break
      case constants.APPLY_VOUCHER_FAILED:
        break
      case constants.SET_VOUCHER_STATUS_CHECK:
        newState.vouchersStatusCheck[action.payload.coupon_code] =
          action.payload.statusCheck
        break
      case constants.RESET_VOUCHER_STATUS_CHECK:
        newState.vouchersStatusCheck = {}
        break

      case constants.GET_VOUCHER_MESSAGE_SUCCESS:
        newState.dataCheckCoupon = action.payload
        break

      case checkoutCartConstants.GET_PROGRESSBAR:
        break
      case checkoutCartConstants.GET_PROGRESSBAR_SUCCESS:
        newState.progressBar = action.payload
        break
      case checkoutCartConstants.GET_PROGRESSBAR_FAILED:
        newState.progressBar = {}
        break

      case checkoutCartConstants.GET_SHOPPING_NOW:
        break
      case checkoutCartConstants.GET_SHOPPING_NOW_SUCCESS:
        newState.shoppingNow = action.payload
        break
      case checkoutCartConstants.GET_SHOPPING_NOW_FAILED:
        newState.shoppingNow = {}
        break

      case checkoutCartConstants.GET_RECOMMEND_PRODUCT:
        break
      case checkoutCartConstants.GET_RECOMMEND_PRODUCT_SUCCESS:
        newState.recommendProducts = action.payload
        break
      case checkoutCartConstants.GET_RECOMMEND_PRODUCT_FAILED:
        newState.recommendProducts = []
        break

      case constants.SYNC_CART_CLIENT: {
        const includeMembershipItem = get(
          action,
          'params.includeMembershipItem'
        )
        const newCart = cartHelper.syncCartClient(
          state.cartData,
          action.products
        )
        let updatedQtys = {
          cartId: get(state, 'cartId'),
          cartItems: [],
          emptyCart: 0,
        }

        updatedQtys.cartItems = map(newCart.items, (item) => ({
          product_id: get(item, 'extension_attributes.product_data.id'),
          qty: item.qty,
          type_id: includeMembershipItem
            ? ''
            : get(item, 'extension_attributes.product_data.type_id'),
          item_id: item.item_id,
          product_type: item.product_type,
        }))

        newState.newUpdatedQtys = updatedQtys
        newState.cartData = { ...newCart }

        if (!getAccessToken()) {
          newState.neededMergedItems = newCart.items
        } else {
          newState.neededMergedItems = null
        }

        break
      }

      case constants.SYNC_PRODUCT_THEN_GOCHECKOUT: {
        break
      }

      case constants.REMOVE_ALLPRODUCTS_INCART: {
        const { id } = state.cartData
        let newCart = cartHelper.removeAllProductsInCart(state.cartData)
        newCart.id = id

        let updatedQtys = {
          cartId: id,
          cartItems: [],
          emptyCart: 1,
        }
        updatedQtys.cartItems = map(newCart.items, (item) => ({
          product_id: get(item, 'extension_attributes.product_data.id'),
          qty: item.qty,
        }))

        newState.newUpdatedQtys = updatedQtys
        newState.cartData = { ...newCart }
        newState.cartItemsCount = get(action, 'payload.items_qty', 0)
        break
      }

      case constants.RESTORE_CART:
        newState.orderId = 0
        newState.createdOrder = null
        newState.selectedTimeslot = null
        break

      case constants.SET_CART_AS_NEED_SYNC:
        newState.cartNeedSync = action.status
        break

      case authenticationConstant.SIGN_UP_SUCCESS:
        newState.cartId = get(action, 'payload.cart_id')
        set(newState, 'cartData.id', +get(action, 'payload.cart_id', 0))
        break

      case authenticationConstant.LOGIN_SUCCESS:
        newState.cartId = get(action, 'payload.cart_id')
        set(newState, 'cartData.id', +get(action, 'payload.cart_id', 0))
        break

      case constants.REMOVE_SHIPPING_ADDRESS_INVALID_QUANTITIES:
        const extension_attributes =
          get(state, 'shippingAddress.extension_attributes') || {}
        const source_stock_code = get(
          extension_attributes,
          'address_validation_data.validate_cart_data.source_stock_code',
          ''
        )
        extension_attributes.address_validation_data = {
          valid_to_ship: true,
          validate_cart_data: {
            show_dl_popup: null,
            allow_checkout: true,
            invalid_qty: [],
            invalid_skus: [],
            source_stock_code,
            error: false,
            message: null,
          },
        }
        newState.shippingAddress = {
          ...state.shippingAddress,
          extension_attributes,
        }
        newState.invalidCartItems = []
        break

      case constants.GET_LAST_ORDER_OOS:
        break
      case constants.GET_LAST_ORDER_OOS_SUCCESS:
        newState.lastOrderOOS = action.payload
        break
      case constants.GET_LAST_ORDER_OOS_FAILED:
        newState.lastOrderOOS = ''
        break

      case constants.SET_CANCELLATION_OPTION:
        newState.cancellationOption = action.payload
        break
      case constants.SET_DEFAULT_CANCELLATION_OPTION:
        newState.defaultCancellationOption = action.payload
        break
      case constants.SET_STORE_PICKUP_ADDRESS:
        newState.storePickupAddress = action.payload
        break
      case constants.SET_FEE_TOOLTIP_VISIBILITY:
        newState.feeTooltipVisible = action.payload.visible
        break
      case constants.IS_RESTORE_CART_PROCESSING:
        newState.isRestoreCartProcessing = action.payload
        break
      case constants.IS_SUBMITTED_PAYMENT:
        newState.isSubmittedPayment = action.payload
        break

      case constants.GET_STORES_TIME_SLOTS:
        break
      case constants.GET_STORES_TIME_SLOTS_SUCCESS:
        state.storesTimeSlots[action.payload.sourcecode] =
          action.payload.timeSlots
        newState.storesTimeSlots = { ...state.storesTimeSlots }
        break
      case constants.GET_STORES_TIME_SLOTS_FAILED:
        break

      case constants.SET_PAYMENT_CARD:
        newState.selectedPaymentCard = action.card
        break

      case constants.GET_PAYMENT_CARDS:
        break
      case constants.GET_PAYMENT_CARDS_SUCCESS:
        newState.paymentCards = action.payload || []
        break
      case constants.GET_PAYMENT_CARDS_FAILED:
        break
      case constants.SET_CART_TOTALS_IS_LOADING:
        newState.cartTotals.isLoading = action.payload
        break
      case constants.SET_SHOW_APPLY_VOUCHER_MODAL_ON_LOAD:
        newState.showApplyVoucherModalOnLoad = action.payload
        break

      case constants.ADD_PAYMENT_CARDS:
        break
      case constants.ADD_PAYMENT_CARDS_SUCCESS:
        newState.paymentCards = action.payload || []
        break
      case constants.ADD_PAYMENT_CARDS_FAILED:
        break

      case constants.SHOW_SLOW_CART_LOADING:
        newState.showSlowCartLoading = true
        break
      case constants.HIDE_SLOW_CART_LOADING:
        newState.showSlowCartLoading = false
        break

      case checkoutCartConstants.GET_PRODUCT_RECOMMENDATION_1_SUCCESS:
        newState.productRecommendations1 = action.payload
        break

      case checkoutCartConstants.GET_PRODUCT_RECOMMENDATION_2_SUCCESS:
        newState.productRecommendations2 = action.payload
        break

      default:
        break
    }
  })

export default persistReducer(persistConfig, reducer)
