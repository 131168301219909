import CONSTANTS from 'containers/Checkout/constants'
import get from 'lodash/get'

export const calculateTotalSavingByItems = (cartItems) => {
  if (!Array.isArray(cartItems)) {
    return 0
  }
  return cartItems.reduce((prev, cur) => {
    return (
      prev +
      (get(cur, 'extension_attributes.product_data.price') - cur.price) *
        cur.qty
    )
  }, 0)
}

export const getValueByCode = (segments, code) => {
  if (!Array.isArray(segments)) {
    return 0
  }
  const segment = segments.find((item) => item.code === code)
  return segment ? segment.value : 0
}

export const getDefaultShippingFeeLimit = (shippingFees) => {
  if (!Array.isArray(shippingFees)) {
    return 0
  }
  const fee = shippingFees.find((item) => item.total === 0)
  return fee ? fee.shipping_fee : 0
}

export const checkCartHasOnlyMC = (items) =>
  items.every(({ product_type }) => product_type === 'customer_membership')

export const calculateTotalSavings = (
  defaultShippingFee,
  cartData,
  cartTotals
) => {
  const totalSegments = get(cartTotals, 'total_segments') || []
  const cartItems = get(cartData, 'items') || []
  const total_saved_by_items = calculateTotalSavingByItems(cartItems)
  const cartHasOnlyMC = checkCartHasOnlyMC(cartItems)
  const promoDiscountAmount = getValueByCode(
    totalSegments,
    'lander_promotion_amount'
  )
  const personalShoppingFee = getValueByCode(totalSegments, 'fee')
  const discountAmount = get(cartTotals, 'discount_amount', 0)

  const solaneItemsOnly = getIsSolaneItemsOnly(totalSegments)
  const gtcItemsOnly = getIsGTCItemsOnly(totalSegments)

  const solaneBase = getSolaneBaseDeliveryFee(totalSegments)
  const solaneFee = getSolaneDeliveryFee(totalSegments)
  const solaneSavings = solaneBase - solaneFee

  const gtcBase = getGtcBaseDeliveryFee(totalSegments)
  const gtcFee = getGtcDeliveryFee(totalSegments)
  const gtcSavings = gtcBase - gtcFee

  const otherItemsOnly = solaneItemsOnly || gtcItemsOnly

  const originalPersonalShoppingFee = !cartHasOnlyMC
    ? getValueByCode(totalSegments, 'base_fee')
    : 0
  const shippingAmount = cartHasOnlyMC
    ? defaultShippingFee
    : get(cartTotals, 'shipping_amount', 0)

  const finalShippingFee = Math.max(defaultShippingFee - shippingAmount, 0)
  const finalPersonalShopperFee =
    originalPersonalShoppingFee - personalShoppingFee
  if (otherItemsOnly) {
    return total_saved_by_items - discountAmount + promoDiscountAmount
  }
  return (
    total_saved_by_items -
    discountAmount +
    promoDiscountAmount +
    finalShippingFee +
    finalPersonalShopperFee +
    solaneSavings +
    gtcSavings
  )
}

export const getIsSolaneItemsOnly = (totalSegments) =>
  get(
    totalSegments.find((x) => x.code === CONSTANTS.totals.allSolaneItem),
    'value',
    false
  )

export const getIsGTCItemsOnly = (totalSegments) =>
  get(
    totalSegments.find((x) => x.code === CONSTANTS.totals.allGTCAldisItem),
    'value',
    false
  )

export const getSolaneDeliveryFee = (totalSegments) =>
  get(
    totalSegments.find((x) => x?.code === CONSTANTS.totals.solaneDeliveryFee),
    'value',
    0
  )

export const getSolaneBaseDeliveryFee = (totalSegments) =>
  get(
    totalSegments.find(
      (x) => x?.code === CONSTANTS.totals.solaneBaseDeliveryFee
    ),
    'value',
    0
  )

export const getGtcDeliveryFee = (totalSegments) =>
  get(
    totalSegments.find((x) => x?.code === CONSTANTS.totals.gtcAldisDeliveryFee),
    'value',
    0
  )

export const getGtcBaseDeliveryFee = (totalSegments) =>
  get(
    totalSegments.find(
      (x) => x?.code === CONSTANTS.totals.gtcAldisBaseDeliveryFee
    ),
    'value',
    0
  )
