import config from 'global-config'
import checkoutCartSaga from './screens/CheckoutCart/saga'
import cartHelper from 'containers/Checkout/helpers/cart'
import {
  call,
  put,
  takeLatest,
  select,
  delay,
  all,
  take,
  race,
  takeEvery,
  debounce,
} from 'redux-saga/effects'
import request, {
  setAccessToken,
  getAccessToken,
  setStoreCode,
  getStoreCode,
} from 'utils/request'
import * as mapper from 'containers/Checkout/helpers/mapper'
import { cloneDeep, get, findIndex, map, each, isEmpty, size } from 'lodash'
import axios from 'axios'
import * as globalActions from 'containers/Landers/actions'
import * as navigation from 'utils/navigation'
import { SCREEN, PAYMENT_METHOD } from 'global-constant'
import {
  cartHasMembership,
  checkUserIsMembership,
  checkMembershipIsExpired,
  checkCartHasOnlyMembershipSku,
} from 'utils/validate'
import { showError } from 'utils/notification'
import { formatDateToTimeZone, toJson } from 'utils/helper'
import * as promotionHelper from 'containers/Checkout/helpers/promotions'
import * as modalActions from 'containers/Modals/actions'
import * as membershipActions from 'containers/Membership/actions'
import * as checkoutActions from 'containers/Checkout/actions'
import * as actions from './actions'
import * as constants from './constants'
import * as selectors from './selectors'
import { isMobile } from 'react-device-detect'
import { submitPaymentForm } from 'utils/form'
import URL from 'constant/urls'
import {
  makeSelectCurrentLocation,
  makeSelectCurrentUser,
  makeSelectGlobalConfig,
} from 'containers/Landers/selectors'
import { loadItem, saveItem } from 'helper/LocalStorageHelper'
import localStore from 'constant/localStore'
import { history } from 'configureStore'
import { getProgressBar } from './screens/CheckoutCart/actions'
import urls from 'constant/urls'
import dayjs from 'dayjs'
import { trackEvent, trackingEvent } from 'utils/firebase'
import { voucherRequestHandlerMap } from './helpers/voucher'
import { ACTION_TYPE } from 'constant/voucher'
import { updateShippingAddress } from 'containers/Checkout/actions'
import { rehydrateStoreViewCode } from 'containers/Landers/actions'
import { SYNC_CART_ADDRESS_CHANGED } from 'containers/Landers/constants'
import { getStoreViewUrl } from 'utils/request'

const RETRY_LIMIT = 2
const RETRY_DELAY = 3000

axios.interceptors.response.use(undefined, (err) => {
  const { config, message } = err
  if (!config || !config.retry) {
    return Promise.reject(err)
  }

  // retry while Network timeout or Network Error
  if (!(message.includes('timeout') || message.includes('Network Error'))) {
    return Promise.reject(err)
  }

  config.retry -= 1
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, config.retryDelay || 1000)
  })

  return delayRetryRequest.then(() => axios(config))
})

function* onCreateCart(action) {
  let requestURL = `${config.apiUrl}/guest-carts`

  if (getAccessToken()) {
    requestURL = `${config.apiUrl}/carts/mine`
  }

  try {
    const cartMaskId = yield call(request, requestURL, {
      method: 'POST',
    })

    yield put(actions.createCartSuccess(cartMaskId))
    if (action.params.addMembership) {
      yield delay(300)
      const { membershipRequest } = action.params
      yield put(membershipActions.applyMembership(membershipRequest))
    }
    yield put(actions.getCartInfo())
    yield put(actions.getCartTotals())
  } catch (err) {
    yield put(actions.createCartFailed(err))
  }
}

function* onGetCartInfo(action) {
  const getCartMaskId = (state) => selectors.makeSelectCartMaskId()(state)
  const cartMaskId = yield select(getCartMaskId)

  let requestURL = `${config.apiUrl}/guest-carts/${cartMaskId}`

  yield put(
    globalActions.showPartialLoader({
      getCartInfoLoading: true,
    })
  )

  if (getAccessToken()) {
    requestURL = `${config.apiUrl}/carts/mine`
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(
      globalActions.showPartialLoader({
        getCartInfoLoading: false,
      })
    )
    const cartData = cartHelper.calculateCart(res)
    yield put(actions.getCartInfoSuccess(cartData))
    yield put(actions.getCartRules())
    if (action.needReloadTotals) {
      yield put(actions.getCartTotals())
    }
    if (getAccessToken()) {
      const getNeededMergedItems = (state) =>
        selectors.makeSelectNeededMergedItems()(state)
      const neededMergedItems = yield select(getNeededMergedItems)

      yield put(checkoutActions.getLastOrderOOS())
      // User has items needed to merge to cart
      if (neededMergedItems) {
        const mergedCartItems = cloneDeep(res.items)

        each(neededMergedItems, (neededMergedItem) => {
          const duplicatedItemIdx = findIndex(
            mergedCartItems,
            (cartItem) => neededMergedItem.sku === cartItem.sku
          )

          if (duplicatedItemIdx > -1) {
            mergedCartItems[duplicatedItemIdx].qty += neededMergedItem.qty
          } else {
            mergedCartItems.push(neededMergedItem)
          }
        })

        const syncProducts = map(mergedCartItems, (cartItem) => ({
          data: get(cartItem, 'extension_attributes.product_data'),
          qty: cartItem.qty,
        }))

        yield put(
          actions.syncCartClient(syncProducts, {
            delay: 500,
            needReloadTotals: true,
            includeMembershipItem: true,
          })
        )
        yield put(actions.syncProductThenGoCheckout())
        // Check if user has items in cart
      } else if (action.redirectTo) {
        if (get(res, 'items.length')) {
          if (action.redirectTo === SCREEN.CHECKOUT_CART) {
            navigation.navigate(action.redirectTo, {
              validateMembership: true,
            })
            // yield put(actions.validateCartItems());
            yield put(actions.syncProductThenGoCheckout())
          }
        } else {
          // case: the first time login, we need to check membership
          navigation.navigate('/', {
            validateMembership: true,
          })
        }
      }
    }
  } catch (err) {
    const errorJson = toJson(err)
    yield put(
      globalActions.showPartialLoader({
        getCartInfoLoading: false,
      })
    )
    if (
      err.status === 404 &&
      get(errorJson, 'parameters.fieldName') === 'cartId'
    ) {
      yield put(actions.createCart())
    }
    yield put(actions.getCartInfoFailed(err))
  }
}

function* onGetCartTotals(action) {
  const globalConfig = yield select((state) => makeSelectGlobalConfig()(state))
  const globalCampaign = get(globalConfig, 'campaign')
  const globalStartTime = get(globalCampaign, 'start_time')
  const globalEndTime = get(globalCampaign, 'end_time')
  const globalStatus = get(globalCampaign, 'status')
  const campaignRedirect = get(globalConfig, 'campaign_page_redirection')

  const deviceType = isMobile ? 'm-site' : 'desktop'
  const getCartMaskId = (state) => selectors.makeSelectCartMaskId()(state)
  const cartMaskId = yield select(getCartMaskId)

  const getSelectedTimeslot = (state) =>
    selectors.makeSelectSelectedTimeslot()(state)
  const selectedTimeslot = yield select(getSelectedTimeslot)
  let requestURL = `${config.apiUrl}/guest-carts/${cartMaskId}/totals-information`

  if (getAccessToken()) {
    requestURL = `${config.apiUrl}/carts/mine/totals-information`
  }

  try {
    const cartData = yield select((state) =>
      selectors.makeSelectCartData()(state)
    )
    const cartItems = get(cartData, 'items') || []
    const currentLocation = yield select((state) =>
      makeSelectCurrentLocation()(state)
    )
    let townshipId =
      get(currentLocation, 'extension_attributes.township_id') ||
      get(currentLocation, 'barangay.id')

    const isStorePickup = get(selectedTimeslot, 'storeAddress')

    if (isStorePickup) {
      townshipId = get(isStorePickup, 'townshipid', '')
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      sourcecode: getStoreCode() || '',
      townshipid: townshipId || '',
      'Device-Type': deviceType,
    }

    const res = yield call(axios, requestURL, {
      retry: RETRY_LIMIT,
      retryDelay: RETRY_DELAY,
      method: 'POST',
      headers,
      data: {
        addressInformation: {
          address: {
            countryId: 'PH',
            postcode: null,
          },
          shipping_method_code: 'bestway',
          shipping_carrier_code: 'tablerate',
        },
      },
    })

    // Reload to fix totals-information API in flash sale campaign
    const forceReload =
      !window.location.pathname.includes(urls.checkoutSuccess) &&
      campaignRedirect &&
      globalStatus &&
      dayjs
        .tz()
        .isBetween(
          formatDateToTimeZone(globalStartTime),
          formatDateToTimeZone(globalEndTime)
        ) &&
      Array.isArray(res.data.items) &&
      Array.isArray(cartData.items) &&
      ((res.data.items.length === 0 && cartData.items.length > 0) ||
        (cartData.items.length > 0 &&
          res.data.items.length > 0 &&
          cartData.items.length !== res.data.items.length))
    if (forceReload) {
      window.location.reload()
      return
    }

    // fix empty cart or membership when checkout
    let pathname = get(window, 'location.pathname', '')
    if (
      pathname === URL.checkoutDelivery ||
      pathname === SCREEN.CHECKOUT_TIMESLOTS ||
      pathname === SCREEN.CHECKOUT_DELIVERY_ADDRESS ||
      pathname === SCREEN.CHECKOUT_PICKUP ||
      pathname === SCREEN.CHECKOUT_PAYMENT
    ) {
      if (cartItems.length === 0) {
        navigation.navigate(SCREEN.CHECKOUT_CART)
      } else if (!cartHasMembership(cartItems)) {
        const isExpire = checkMembershipIsExpired()
        const isMembership = checkUserIsMembership()
        if (isExpire) {
          navigation.navigate(SCREEN.MEMBERSHIP_LIST, {
            showPopup: 'renew',
          })
        } else if (!isMembership) {
          // If user don't have membership
          navigation.navigate(SCREEN.MEMBERSHIP_LIST, {
            showPopup: 'apply',
          })
        }
      }
    }

    const dataCoupon = yield select((state) =>
      selectors.makeSelectDataCoupon()(state)
    )

    if (
      res.data?.coupon_code &&
      (dataCoupon === undefined || dataCoupon?.success === false)
    ) {
      yield put(
        actions.getVoucherMessage({
          couponCode: res.data?.coupon_code,
          isValid: true,
        })
      )
      yield take(constants.GET_VOUCHER_MESSAGE_SUCCESS)
    }

    yield put(actions.getCartTotalsSuccess(res.data))
  } catch (err) {
    yield put(actions.getCartTotalsFailed(err))
    yield put(modalActions.showErrorMessageModal(true))
  }
}

function* onValidateCartItems(action) {
  const getCartMaskId = (state) => selectors.makeSelectCartMaskId()(state)
  const cartMaskId = yield select(getCartMaskId)
  const isLoggedIn = getAccessToken() ? true : false
  let requestURL = `${config.apiUrl}/ldmultistores/guest-validatecart/${cartMaskId}`

  if (isLoggedIn) {
    requestURL = `${config.apiUrl}/ldmultistores/validatecart`
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({}),
    })

    const membershipHasError = get(res, 'membership_has_error', false)

    if (membershipHasError) {
      window.location.reload()
    }

    if (isLoggedIn) {
      yield put(globalActions.getUserInfo())
    }

    const invalidQty = get(res, 'invalid_qty') || []
    yield put(actions.validateCartItemsSuccess(invalidQty))
    if (invalidQty.length > 0) {
      yield put(modalActions.showInvalidCartItemsModal(invalidQty))
    }
  } catch (err) {
    yield put(actions.validateCartItemsSuccess([]))
  }
}

function* onGetDeliveryTimeslots({ payload }) {
  const requestURL = `${config.apiUrl}/ldmultistores/deliverydate`
  yield put(globalActions.showLoader())
  try {
    if (payload?.refreshShippingAddress) {
      yield put(updateShippingAddress(false, { isUpdateAddress: true }))
      yield take(constants.UPDATE_SHIPPING_ADDRESS_SUCCESS)
    }

    const currentLocation = yield select((state) =>
      makeSelectCurrentLocation()(state)
    )

    const townshipId =
      get(currentLocation, 'extension_attributes.township_id') ||
      get(currentLocation, 'barangay.id')

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      sourcecode: getStoreCode() || '',
      townshipid: townshipId || '',
    }
    const res = yield call(axios, requestURL, {
      method: 'GET',
      headers,
    })

    const allTimeslots = get(res.data, '[0].time_slot')

    yield put(globalActions.hideLoader())
    yield put(actions.getDeliveryTimeslotsSuccess(allTimeslots || []))
  } catch (err) {
    showError(get(err, 'message'))
    yield put(globalActions.hideLoader())
    yield put(actions.getDeliveryTimeslotsFailed(err))
  }
}

function* onGetStoresTimeslots(action) {
  const requestURL = `${config.apiUrl}/ldmultistores/deliverydate${
    !getAccessToken() ? '-anonymous' : ''
  }`
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      sourcecode: action.params.sourcecode || '',
    }
    const res = yield call(axios, requestURL, {
      method: 'GET',
      headers,
    })

    const timeSlots = get(res.data, '[0].time_slot')

    yield put(
      actions.getStoresTimeSlotsSuccess({
        timeSlots: timeSlots || [],
        sourcecode: action.params.sourcecode,
      })
    )
  } catch (err) {
    yield put(actions.getStoresTimeSlotsFailed(err))
  }
}

function* onValidateDeliveryTimeslot(action) {
  const requestURL = `${config.apiUrl}/ldmultistores/deliverydate/validate`
  yield put(globalActions.showLoader())

  const getSelectedTimeslot = (state) =>
    selectors.makeSelectSelectedTimeslot()(state)

  const selectedTimeslot = yield select(getSelectedTimeslot)

  if (!selectedTimeslot) {
    yield put(globalActions.hideLoader())
    showError('Please select delivery timeslot')
  } else {
    try {
      const res = yield call(request, requestURL, {
        method: 'POST',
        body: JSON.stringify({
          date: selectedTimeslot.date,
          time_id: selectedTimeslot.value,
          location_store: getStoreCode(),
        }),
      })

      if (!res.errors) {
        yield put(actions.updateShippingAddress())
        yield put(actions.validateDeliveryTimeslotSuccess(res))
      } else {
        showError('Delivery timeslot invalid')
        yield put(globalActions.hideLoader())
        yield put(actions.validateDeliveryTimeslotFailed(res))
      }
    } catch (err) {
      showError(get(err, 'message') || 'Validate delivery date failed')
      yield put(actions.getDeliveryTimeslots())
      yield put(globalActions.hideLoader())
      yield put(actions.validateDeliveryTimeslotFailed(err))
    }
  }
}

function* onGetShippingMethods(action) {
  const requestURL = `${config.apiUrl}//V1/carts/${action.cartId}/shipping-methods`
  yield put(globalActions.showLoader())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(globalActions.hideLoader())
    yield put(actions.getShippingMethodsSuccess(res))
  } catch (err) {
    yield put(globalActions.hideLoader())
    yield put(actions.getShippingMethodsFailed(err))
  }
}

function* onEstimateShippingMethods(action) {
  const getCartMaskId = (state) => selectors.makeSelectCartMaskId()(state)
  const cartMaskId = yield select(getCartMaskId)
  let requestURL = `${config.baseUrl}/rest/default/V1/guest-carts/${cartMaskId}/estimate-shipping-methods`
  if (getAccessToken()) {
    requestURL = `${config.baseUrl}/rest/default/V1/carts/mine/estimate-shipping-methods`
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({
        address: {
          region: '',
          country_id: 'PH',
          postcode: null,
        },
      }),
    })
    yield put(actions.estimateShippingMethodsSuccess(res))
  } catch (err) {
    yield put(actions.estimateShippingMethodsFailed(err))
  }
}

function* onUpdateShippingAddress(action) {
  const isUpdateAddress = action.params.isUpdateAddress || false
  const requestURL = `${config.apiUrl}/carts/mine/shipping-information`

  const getShippingAddress = (state) =>
    selectors.makeSelectShippingAddress()(state)
  const address = yield select(getShippingAddress)
  const cartData = yield select(selectors.makeSelectCartData())
  const cartHasOnlyMembershipSKU = checkCartHasOnlyMembershipSku(cartData.items)

  if (cartData.items.length === 0 || cartHasOnlyMembershipSKU) return

  let townshipId =
    get(address, 'extension_attributes.township_id') ||
    get(address, 'barangay.id')

  const getSelectedTimeslot = (state) =>
    selectors.makeSelectSelectedTimeslot()(state)
  const selectedTimeslot = yield select(getSelectedTimeslot)
  let shippingAddress = mapper.addressToShippingAddress(
    address,
    selectedTimeslot
  )

  if (get(selectedTimeslot, 'storeAddress.townshipid')) {
    shippingAddress = mapper.mapStoreAddressToShippingAddress(
      shippingAddress,
      selectedTimeslot.storeAddress
    )
    townshipId = get(selectedTimeslot, 'storeAddress.townshipid')
    yield put(getProgressBar({ townshipIdStorePickup: townshipId }))
  } else {
    yield put(getProgressBar())
  }

  const billingAddress = { ...shippingAddress }

  const requestData = {
    addressInformation: {
      shipping_address: shippingAddress,
      billing_address: billingAddress,
      extension_attributes: {
        amdeliverydate_comment: get(
          selectedTimeslot,
          'amdeliverydate_comment',
          ''
        ),
        amdeliverydate_date: get(selectedTimeslot, 'date', ''),
        amdeliverydate_time: get(selectedTimeslot, 'value', ''),
      },
      shipping_carrier_code: 'tablerate',
      shipping_method_code: 'bestway',
    },
  }
  yield put(globalActions.showLoader())
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      sourcecode: getStoreCode() || '',
      townshipid: townshipId || '',
    }

    const res = yield call(axios, requestURL, {
      method: 'POST',
      headers,
      data: requestData,
    })

    if (!isUpdateAddress) {
      navigation.navigate(SCREEN.CHECKOUT_PAYMENT)
    }

    yield put(globalActions.hideLoader())
    yield put(actions.updateShippingAddressSuccess(res))
    // update latest cart totals information
    yield put(actions.getCartTotalsSuccess(get(res, 'data.totals')))
  } catch (err) {
    if (get(err, 'response.status') === 401) {
      history.push('/', { isTokenExpired: true })
    } else {
      yield put(globalActions.hideLoader())
      yield put(actions.updateShippingAddressFailed(err))
    }
  }
}

function* onUpdatePaymentInformation(action) {
  let requestURL = `${config.apiUrl}/carts/${action.cartId}/payment-information`

  if (getAccessToken()) {
    requestURL = `${config.apiUrl}/carts/mine/payment-information`
  }
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))

  const address = yield select((state) =>
    selectors.makeSelectShippingAddress()(state)
  )

  const cartId = yield select((state) => selectors.makeSelectCartId()(state))

  const paymentMethod = yield select((state) =>
    selectors.makeSelectSelectedPaymentMethod()(state)
  )

  const getSelectedTimeslot = (state) =>
    selectors.makeSelectSelectedTimeslot()(state)
  const selectedTimeslot = yield select(getSelectedTimeslot)
  let cancellationOption = yield select((state) =>
    selectors.makeSelectCancellationOption()(state)
  )
  let billingAddress = null

  if (!action.cartHasOnlyMembership) {
    billingAddress = mapper.addressToShippingAddress(address, selectedTimeslot)
  } else {
    cancellationOption = ''
  }
  if (!paymentMethod) {
    showError('Please choose payment method')
  } else {
    const requestPayload = {
      billingAddress: billingAddress,
      cartId: cartId,
      paymentMethod: {
        additional_data: !isEmpty(action?.cardInfo)
          ? {
              cancellation_option: cancellationOption,
              card_info: JSON.stringify(action.cardInfo),
            }
          : {
              cancellation_option: cancellationOption,
            },
        method: paymentMethod.code,
        po_number: null,
      },
    }
    yield put(globalActions.showLoader({ isPaymentProcessing: true }))
    try {
      const { res, canceled } = yield race({
        res: call(request, requestURL, {
          method: 'POST',
          body: JSON.stringify(requestPayload),
        }),
        canceled: take(constants.CANCEL_UPDATE_PAYMENT_INFORMATION),
      })

      if (canceled) {
        yield delay(1000)
        yield put(globalActions.hideLoader())
        return yield put(checkoutActions.restoreCart())
      }
      yield put(actions.updatePaymentInformationSuccess(res))

      if (action?.cardInfo?.saveCard) {
        yield put(modalActions.showCardSavedSuccess(true))
      } else {
        yield put(modalActions.showCardSavedSuccess(false))
      }

      if (
        paymentMethod.code === PAYMENT_METHOD.CASH ||
        paymentMethod.code === PAYMENT_METHOD.FREE
      ) {
        navigation.navigate(SCREEN.CHECKOUT_PAYMENT_SUCCESS)
      } else {
        yield delay(500)
        const params = {
          orderId: res,
          customerId: currentUser.id,
        }
        submitPaymentForm(`${config.baseUrl}/gateway/resolver/index`, params)
      }

      yield put(checkoutActions.isSubmittedPayment(false))
      yield put(globalActions.hideLoader())
    } catch (err) {
      console.log('err', err)
      showError(get(err, 'message') || 'Failed to place order')
      navigation.navigate(SCREEN.CHECKOUT_TIMESLOTS)
      yield put(globalActions.hideLoader())
      yield put(checkoutActions.isSubmittedPayment(false))
      yield put(actions.updatePaymentInformationFailed(err))
    }
  }
}

function* onGetOrderById(action) {
  let requestURL = `${config.apiUrl}/order-detail/mine?order_id=${action.orderId}`
  yield put(globalActions.showLoader())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.getOrderByIdSuccess(res))
    if (action.reloadShippingAddress) {
      // fix bug duplicate address after checkout, we need map new address for checkout flow
      let idAddress = get(res, 'billing_address.customer_address_id')
      let newShippingAddress
      if (idAddress) {
        newShippingAddress = {
          ...res.billing_address,
          id: idAddress,
        }
      } else {
        newShippingAddress = loadItem(localStore.shippingAddress)
      }
      yield put(checkoutActions.setShippingAddress(newShippingAddress))
      yield put(globalActions.checkLocationSuccess(newShippingAddress))
    }
  } catch (err) {
    yield put(actions.getOrderByIdFailed(err))
    yield delay(500)
    navigation.navigate(SCREEN.CHECKOUT_CART)
  } finally {
    yield put(globalActions.hideLoader())
  }
}

function* onGetPaymentMethods(action) {
  const cartId = yield select((state) => selectors.makeSelectCartId()(state))
  let requestURL = `${config.apiUrl}/ld-storeConfig/${cartId}`
  yield put(actions.validateCartItems())
  yield put(globalActions.showLoader())

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    const list = Object.values(get(res, '[0].payment'))
    const mediaPath = get(res, '[0].mediapath')
    const freePayment = list.find(
      (item) => item.code === 'free' && item.is_available
    )
    const allPaymentMethods = freePayment ? [freePayment] : list

    yield put(globalActions.hideLoader())
    yield put(actions.getMediaPathSuccess(mediaPath))
    yield put(actions.getPaymentMethodsSuccess(allPaymentMethods))
    const mayaVaultMethod = allPaymentMethods?.find(
      (method) => method?.code === PAYMENT_METHOD?.MAYA_VAULT
    )

    if (mayaVaultMethod) {
      yield put(actions.setPaymentMethod(mayaVaultMethod))
    }

    if (allPaymentMethods.length === 1) {
      yield put(actions.setPaymentMethod(allPaymentMethods[0]))
    }

    if (!mayaVaultMethod && allPaymentMethods.length > 1) {
      yield put(actions.setPaymentMethod(null))
    }
  } catch (err) {
    yield put(globalActions.hideLoader())
    yield put(actions.getPaymentMethodsFailed(err))
  }
}

function* onApplyVoucher(action) {
  const voucherCodes = action.payload.voucherCodes || []
  const actionType = action.payload.actionType
  const isMultiSelect = action.payload.isMultiSelect || false

  const getCartMaskId = (state) => selectors.makeSelectCartMaskId()(state)
  const getSelectedTimeslot = (state) =>
    selectors.makeSelectSelectedTimeslot()(state)
  const cartMaskId = yield select(getCartMaskId)
  const selectedTimeslot = yield select(getSelectedTimeslot)
  const currentLocation = yield select((state) =>
    makeSelectCurrentLocation()(state)
  )
  const townshipId =
    get(currentLocation, 'extension_attributes.township_id') ||
    get(currentLocation, 'barangay.id')
  const accessToken = getAccessToken()
  const { couponCode, deviceType, isRetrieveCartTotals = true } = action.payload
  const isDeleteMode = isEmpty(couponCode)
  let requestURL = '',
    method = ''
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  const isStorePickup = get(selectedTimeslot, 'storeAddress')
  if (isStorePickup) {
    headers['sourcecode'] = get(isStorePickup, 'sourcecode', '')
    headers['townshipid'] = get(isStorePickup, 'townshipid', '')
  } else {
    headers['sourcecode'] = getStoreCode() || ''
    headers['townshipid'] = townshipId || ''
  }
  if (isDeleteMode) {
    //remove coupon
    method = 'DELETE'
    requestURL = `${config.apiUrl}/guest-carts/${cartMaskId}/coupons/`
    if (accessToken) {
      headers.authorization = `Bearer ${accessToken}`
      requestURL = `${config.apiUrl}/carts/mine/coupons/`
    }
  } else {
    //apply coupon
    method = 'PUT'
    requestURL = `${config.apiUrl}/guest-carts/${cartMaskId}/coupons/${couponCode}`
    headers['Device-Type'] = deviceType
    if (accessToken) {
      headers.authorization = `Bearer ${accessToken}`
      requestURL = `${config.apiUrl}/carts/mine/coupons/${couponCode}`
    }
  }

  if (isRetrieveCartTotals) {
    yield put(globalActions.showLoader())
  }

  try {
    yield all(
      voucherCodes.map((code) =>
        put(
          checkoutActions.setVouchersStatusCheck({
            coupon_code: code,
            statusCheck: { is_processing: true },
          })
        )
      )
    )

    const voucherRequest = voucherRequestHandlerMap[actionType]({
      voucherCodes,
      cartMaskId,
      selectedTimeslot,
      currentLocation,
    })

    let dataUpdate = isDeleteMode ? '' : couponCode

    yield put(globalActions.hideLoader())

    if (isDeleteMode) {
      yield all([
        put(
          actions.applyCouponSuccess({
            couponCode,
          })
        ),
        put(
          actions.getVoucherMessageSuccess({
            success: 'Your Coupon was successfully removed',
            error: false,
          })
        ),
      ])
      yield delay(2000)
      yield all([
        put(
          actions.applyCouponSuccess({
            couponCode: dataUpdate,
          })
        ),
        put(
          actions.getVoucherMessageSuccess({
            success: false,
            error: false,
          })
        ),
      ])
    } else {
      yield all([
        put(
          actions.applyCouponSuccess({
            couponCode,
          })
        ),
        put(
          actions.getVoucherMessage({
            couponCode,
            isValid: true,
          })
        ),
      ])
      yield take(constants.GET_VOUCHER_MESSAGE_SUCCESS)
    }

    const paymentMethods = yield select((state) =>
      selectors.makeSelectPaymentMethods()(state)
    )

    if (get(paymentMethods, 'length')) {
      yield put(actions.getPaymentMethods())
    }

    if (isRetrieveCartTotals) {
      yield put(actions.getCartTotals())
    }
  } catch (err) {
    yield put(
      actions.getVoucherMessage({
        couponCode,
        isValid: false,
      })
    )
    yield take(constants.GET_VOUCHER_MESSAGE_SUCCESS)
    yield put(globalActions.hideLoader())
  }
}

function* onGetVoucherMessage(action) {
  const { couponCode, isValid } = action.payload
  const requestURL = `${config.apiUrl}/coupon/${couponCode}`

  try {
    const response = yield call(request, requestURL)

    if (isValid) {
      yield put(
        actions.getVoucherMessageSuccess({
          success: response.valid_notif,
          error: false,
        })
      )

      return
    }

    yield put(
      actions.getVoucherMessageSuccess({
        success: false,
        error: [
          response?.invalid_notif || '',
          'Sorry, voucher is not applicable. Please check typing errors, and make sure conditions are met.',
        ],
      })
    )
  } catch (err) {
    console.log('error onGetVoucherMessage :', err)

    // in case there is other error in API let's just show a generic error message
    yield put(
      actions.getVoucherMessageSuccess({
        success: false,
        error: [
          'Sorry, voucher is not applicable. Please check typing errors, and make sure conditions are met.',
        ],
      })
    )
  }
}

function* onSyncCartClient(action) {
  const redirectCart = action.redirectCart
  yield delay(action.delay)
  yield put(
    actions.setCartAsNeedSync(
      !window.location.pathname.includes(URL.checkoutCart)
    )
  )
  yield put(
    actions.syncCartServer(
      action.delay,
      action.needReloadTotals,
      true,
      redirectCart,
      action.isCartUpdate
    )
  )

  yield delay(100)
  yield put(actions.getCartRules(action.actionSync))

  if (action.isOutOfStock) {
    const invalidCartItems = yield select((state) =>
      selectors.makeSelectInvalidCartItems()(state)
    )
    if (Array.isArray(invalidCartItems)) {
      const nextItems = invalidCartItems.filter(
        (item) => item.product_sku !== action.outOfStockSku
      )
      yield put(actions.validateCartItemsSuccess(nextItems))
    }
  }
}

function* onSyncProductThenGoCheckout(action) {
  const isShowLoader = !window.location.pathname.includes(URL.checkoutCart)
  yield delay(100)
  const cartNeedSynced = yield select((state) =>
    selectors.makeSelectCartNeedSync()(state)
  )
  if (isShowLoader) {
    yield put(globalActions.showLoader())
  }
  if (cartNeedSynced) {
    if (cartNeedSynced !== 'membership') {
      yield put(actions.syncCartServer(100, true, true, false, true))
      yield take(constants.SYNC_CART_SERVER_SUCCESS)
    } else {
      yield put(actions.setCartAsNeedSync(false))
    }
    yield put(actions.getCartInfo(true))
    yield take(constants.GET_CART_INFO_SUCCESS)
  } else {
    yield put(actions.getCartTotals())
  }

  // yield put(actions.validateCartItems());
  if (isShowLoader) {
    yield put(globalActions.hideLoader())
  }
  if (!action.noRedirectCart) {
    navigation.navigate(SCREEN.CHECKOUT_CART)
  }
}

function* onRemoveAllProductsIncart(action) {
  yield put(
    modalActions.showCartNotificationModal({
      isRemoved: true,
      isBundled: false,
      qty: action.cartItemsCount,
    })
  )
  yield put(actions.syncCartServer(500, true, true))
  yield put(actions.clearCartRules())
}

function* onSlowCartShow(action) {
  const isShowLoader = window.location.pathname.includes(URL.checkoutCart)
  const cartTotals = yield select((state) =>
    selectors.makeSelectCartTotals()(state)
  )

  const showCartLoading = yield select((state) =>
    selectors.makeSelectShowSlowCartLoading()(state)
  )

  if (cartTotals?.isLoading && isShowLoader && !showCartLoading) {
    yield put(actions.showSlowCartLoading())
  }
}

/**
 *
 * @param {Object} action
 * @param {number} action.delay
 * @param {boolean} action.needReloadTotals
 * @param {boolean} action.needReloadCartInfo
 * @param {boolean} action.redirectCart
 * @param {boolean} action.isCartUpdate
 * @returns {void}
 */
function* onSyncCartServer(action) {
  const requestURL = `${getStoreViewUrl()}/sync-cart`
  yield delay(action.delay)
  const getNewUpdatedQtys = (state) =>
    selectors.makeSelectNewUpdatedQtys()(state)
  let updatedQtys = yield select(getNewUpdatedQtys)
  const currentLocation = yield select((state) =>
    makeSelectCurrentLocation()(state)
  )
  const townshipId =
    get(currentLocation, 'extension_attributes.township_id') ||
    get(currentLocation, 'barangay.id') ||
    get(currentLocation, 'datastore.township_id')

  // we are adding action.isCartUpdate here to update the cart price when switching address
  if (updatedQtys || action.isCartUpdate) {
    const cartId = yield select((state) => selectors.makeSelectCartId2()(state))
    const cartData = yield select((state) =>
      selectors.makeSelectCartData()(state)
    )

    const cartItems = updatedQtys?.cartItems || cartData?.items

    const deleteItemIds = cartItems
      .filter((item) => item.qty === 0 && !!item.item_id)
      .map((item) => item.item_id)

    const nextCartItems = cartItems.reduce((prev, cur) => {
      if (cur.product_type !== 'customer_membership') {
        const productId =
          cur?.product_id || cur?.extension_attributes?.product_data.id

        return [...prev, { product_id: productId, qty: cur.qty }]
      }
      return [...prev]
    }, [])

    let data = {
      cartItems: [...nextCartItems],
      emptyCart: updatedQtys?.emptyCart || false,
      cartId: updatedQtys?.cartId || cartId,
      deleteItemIds: deleteItemIds || [],
    }

    yield put(actions.setCartAsNeedSync(false))
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        sourcecode: getStoreCode() || '',
        townshipid: townshipId || '',
      }

      if (action.isCartUpdate) {
        data.isCartUpdate = 1
      }

      const res = yield call(axios, requestURL, {
        method: 'POST',
        headers,
        data,
      })
      const skuError = get(res, 'data.skuerror') || []
      const showErrorMessageAndRefresh = skuError.some(
        (item) => item.stock === 0
      )

      yield put(
        modalActions.showAddMoreItemModal(get(res, 'data.require_login', false))
      )

      if (get(res, 'data.error')) {
        // sync cart error because of missing storecode
        // reload page to refresh cart data

        if (get(res, 'data.require_login') === true) {
          // to disable reloading of page
          return false
        }

        showError(get(res, 'data.message'))

        return setTimeout(() => {
          window.location.reload()
        }, 1500)
      }
      if (showErrorMessageAndRefresh) {
        showError('Your desired quantity is not available for this product.')
        return setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
      if (action.needReloadTotals) {
        yield put(actions.getCartTotals())
      }
      if (action.needReloadCartInfo) {
        yield put(actions.getCartInfo())
      }
      if (action.redirectCart) {
        navigation.navigate(URL.checkoutCart)
      }
      yield put(actions.syncCartServerSuccess(get(res, 'data.items') || []))
    } catch (err) {
      if (action.redirectCart) {
        navigation.navigate(URL.checkoutCart)
      }
      yield put(globalActions.hideLoader())
      yield put(actions.syncCartServerSuccess([]))
    }
  } else {
    yield put(actions.syncCartServerSuccess('Sync success'))
  }
}

function* onGetCartRules(action) {
  const requestURL = `${config.baseUrl}/api/v1/promotions/cartrule`
  yield delay(action.delay)

  const cartData = yield select((state) =>
    selectors.makeSelectCartData()(state)
  )

  const cartRuleRequestData = map(cartData.items, (cartItem) => ({
    sku: cartItem.sku,
    qty: cartItem.qty,
  }))

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(cartRuleRequestData),
    })

    const newCartData = promotionHelper.applyRulesToCart(
      cartData,
      res,
      action.actionSync
    )

    yield put(actions.getCartRulesSuccess(newCartData))
    yield put(checkoutActions.isRestoreCartProcessing(false))
  } catch (err) {
    const mockUpCartRules = map(cartData.items, function (cartItem) {
      const mockupRule = {
        discount: null,
        promotion_name: null,
        promotion_type: 'normal',
        skus: {},
      }
      mockupRule.skus[cartItem.sku] = {}
      mockupRule.skus[cartItem.sku].qty = cartItem.qty
      return mockupRule
    })

    yield put(
      actions.getCartRulesSuccess(
        promotionHelper.applyRulesToCart(
          cartData,
          mockUpCartRules,
          action.actionSync
        )
      )
    )
  }
}

function* onPersistRehydrate(action) {
  const pathname = window.location.pathname

  if (pathname !== URL.chatPlugin) {
    if (action.key === 'global' && get(action, 'payload.accessToken')) {
      const accessToken = get(action, 'payload.accessToken')

      setAccessToken(accessToken)

      if (accessToken) {
        yield put(
          globalActions.getUserInfo({
            needUpdateID: true,
          })
        )
      }
    }

    if (action.key === 'global') {
      const currentLocation = get(action, 'payload.currentLocation')
      const storeCode = get(currentLocation, 'storeCode')

      setStoreCode(storeCode)
      yield put(rehydrateStoreViewCode(storeCode))
    }

    delay(40)
    if (action.key === 'checkout') {
      if (getAccessToken()) {
        yield put(actions.getCartInfo())
      } else {
        const cartMaskId = get(action, 'payload.cartMaskId')
        if (cartMaskId) {
          yield put(actions.getCartInfo())
        } else {
          yield put(actions.createCart())
        }
      }
    }

    yield delay(100)
    yield put(globalActions.setPersistorLoaded())
  } else {
    yield put(globalActions.setPersistorLoaded())
  }
}

function* onRestoreCart(action) {
  const requestURL = `${config.apiUrl}/carts/mine/restore-quote`
  yield put(checkoutActions.isRestoreCartProcessing(true))
  try {
    const res = yield call(request, requestURL, { method: 'GET' })
    yield put(actions.createCart())
    if (!res.error && !action.noRedirectCart) {
      navigation.navigate(SCREEN.CHECKOUT_CART)
    }
  } catch (err) {
    yield put(checkoutActions.isRestoreCartProcessing(false))
  }
}

// support mapping shipping address after payment
// eslint-disable-next-line require-yield
function* onSetShippingAddress(action) {
  saveItem(localStore.shippingAddress, action.address)
}

function* onGetLastOrderOOS(action) {
  const requestURL = `${config.apiUrl}/ld-customers/last-order-oos`
  try {
    const res = yield call(request, requestURL, { method: 'GET' })
    yield put(checkoutActions.getLastOrderOOSSuccess(res))
  } catch (err) {
    yield put(checkoutActions.getLastOrderOOSFailed())
  }
}

function* onGetPaymentCards(action) {
  yield put(globalActions.showLoader())
  const getSelectedPaymentCard = (state) =>
    selectors.makeSelectSelectedPaymentCard()(state)
  const selectedPaymentCard = yield select(getSelectedPaymentCard)

  const requestURL = `${config.baseUrl}/rest/V1/maya-vault/customer-card`
  try {
    const res = yield call(request, requestURL, { method: 'GET' })
    yield put(checkoutActions.getPaymentCardsSuccess(res))

    if (!selectedPaymentCard && res) {
      const defaultPaymentCard = res.find((x) => x.default === true) || res[0]
      trackEvent(trackingEvent.clickCard)
      yield put(checkoutActions.setPaymentCard(defaultPaymentCard))
    }
    yield put(globalActions.hideLoader())
  } catch (err) {
    console.log('err', err)
    yield put(globalActions.hideLoader())
    yield put(checkoutActions.getPaymentCardsFailed(err))
  }
}

function* onAddPaymentCards(action) {
  const currentUser = yield select(makeSelectCurrentUser())
  const requestURL = `${config.baseUrl}/paymaya/card/createCustomerCard`
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({ ...action?.params, customerId: currentUser?.id }),
    })
    yield put(checkoutActions.AddPaymentCardSuccess(res))
    yield put(checkoutActions.getPaymentCards())
    yield put(modalActions.hideAddCardModal())
    yield put(modalActions.hideAddCardModal())
  } catch (err) {
    console.log('err', err)
    yield put(checkoutActions.AddPaymentCardFailed(err))
  }
}

/**
 *
 * @param {Object} action
 * @param {string} action.pathname
 * @param {boolean} action.isStoreCodeChanged
 */
function* onSyncCartWhenAddressChanged(action) {
  const { pathname, isStoreCodeChanged } = action

  try {
    const cartData = yield select((state) =>
      selectors.makeSelectCartData()(state)
    )

    if (size(cartData.items) > 0) {
      yield put(checkoutActions.syncCartServer(100, true, true, false, true))
      yield take(constants.SYNC_CART_SERVER_SUCCESS)
      yield delay(200)
    }

    if (isStoreCodeChanged && pathname !== '/') {
      window.location.reload()
    }
  } catch (e) {
    console.log('error :', e)
  }
}

// Individual exports for testing
export default function* checkoutSaga() {
  yield takeLatest(constants.CREATE_CART, onCreateCart)
  yield takeLatest(constants.GET_CART_TOTALS, onGetCartTotals)
  yield takeLatest(constants.GET_CART_INFO, onGetCartInfo)
  yield takeLatest(constants.VALIDATE_CART_ITEMS, onValidateCartItems)
  yield takeLatest(constants.GET_DELIVERY_TIMESLOTS, onGetDeliveryTimeslots)
  yield takeLatest(
    constants.VALIDATE_DELIVERY_TIMESLOT,
    onValidateDeliveryTimeslot
  )
  yield takeLatest(constants.GET_SHIPPING_METHODS, onGetShippingMethods)
  yield takeLatest(
    constants.ESTIMATE_SHIPPING_METHODS,
    onEstimateShippingMethods
  )
  yield takeLatest(constants.UPDATE_SHIPPING_ADDRESS, onUpdateShippingAddress)
  yield takeLatest(
    constants.UPDATE_PAYMENT_INFORMATION,
    onUpdatePaymentInformation
  )
  yield takeLatest(constants.GET_ORDER_BYID, onGetOrderById)
  yield takeLatest(constants.GET_PAYMENT_METHODS, onGetPaymentMethods)
  yield takeEvery(constants.APPLY_VOUCHER, onApplyVoucher)
  yield takeLatest(constants.SYNC_CART_CLIENT, onSyncCartClient)
  yield takeLatest(
    constants.REMOVE_ALLPRODUCTS_INCART,
    onRemoveAllProductsIncart
  )
  yield takeLatest(constants.SYNC_CART_SERVER, onSyncCartServer)
  yield takeLatest(constants.GET_CART_RULES, onGetCartRules)
  yield takeLatest(
    constants.SYNC_PRODUCT_THEN_GOCHECKOUT,
    onSyncProductThenGoCheckout
  )
  yield takeLatest(constants.RESTORE_CART, onRestoreCart)
  yield takeLatest(constants.SET_SHIPPING_ADDRESS, onSetShippingAddress)
  yield takeLatest(constants.GET_LAST_ORDER_OOS, onGetLastOrderOOS)
  yield takeEvery(constants.GET_STORES_TIME_SLOTS, onGetStoresTimeslots)
  yield takeLatest(constants.GET_PAYMENT_CARDS, onGetPaymentCards)
  yield takeLatest(constants.ADD_PAYMENT_CARDS, onAddPaymentCards)
  yield takeLatest('persist/REHYDRATE', onPersistRehydrate)
  yield debounce(3000, constants.SYNC_CART_CLIENT, onSlowCartShow)
  yield debounce(3000, constants.SYNC_PRODUCT_THEN_GOCHECKOUT, onSlowCartShow)
  yield takeLatest(SYNC_CART_ADDRESS_CHANGED, onSyncCartWhenAddressChanged)
  yield takeLatest(constants.GET_VOUCHER_MESSAGE, onGetVoucherMessage)
  yield all([checkoutCartSaga()])
}
