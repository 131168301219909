import { get, trim, replace, split, filter, find, countBy } from 'lodash'
import dayjs from 'dayjs'
import * as productUtils from 'utils/product'

let currentUser = {}
let cartData = {}
let cartItems = []

export const setCurrentUser = (userInfo) => {
  currentUser = userInfo
}

export const setCartData = (newCartData) => {
  cartData = newCartData
  cartItems = newCartData.items || []
}

export const getCartData = () => {
  return cartData
}

export const getCurrentUser = () => currentUser

export const checkUserIsMembership = () => {
  const membershipCode = get(
    currentUser,
    'extension_attributes.membership_info.code',
    null
  )
  return membershipCode !== null
}
export const checkMembershipIsExpired = () => {
  const expiredDate = get(
    currentUser,
    'extension_attributes.membership_info.expired_date'
  )
  return expiredDate && dayjs(expiredDate).isBefore(dayjs())
}

export const getCurrentUserFullName = (params = { prefix: '' }) => {
  const firstName = trim(
    replace(get(currentUser, 'firstname', ''), /\s+/g, ' ')
  )
  const lastName = trim(replace(get(currentUser, 'lastname', ''), /\s+/g, ' '))

  if (!firstName && !lastName) {
    const email = get(currentUser, 'email', '')
    return get(split(email, '@'), '[0]', '')
  }
  return `${params.prefix}${firstName} ${lastName}`
}

export const cartHasMembership = (items) => {
  const validItems = filter(items, (item) => {
    return item.qty > 0
  })

  return find(validItems, (cartItem) => {
    return (
      get(cartItem, 'extension_attributes.product_data.type_id') ===
      'customer_membership'
    )
  })
}

export const cartHasOutstocks = () => {
  const outStockItem = find(cartItems, (cartItem) => {
    const inStockQty = productUtils.getInStockValue(
      get(cartItem, 'extension_attributes.product_data')
    )
    return inStockQty < cartItem.qty && cartItem.qty
  })

  return outStockItem
}

export const cartHasOnlyMembership = (items = null) => {
  if (!items) {
    items = cartItems
  }

  const validItemsCount = countBy(items, (item) => {
    return item.qty > 0
  })

  const validItems = filter(items, (item) => {
    return item.qty > 0
  })

  if (cartHasMembership(validItems) && validItemsCount.true === 1) {
    return true
  }

  return false
}

export const isCustomerMembership = (item) =>
  item && item.product_type === 'customer_membership'

export const checkCartHasOnlyMembershipSku = (items) =>
  Array.isArray(items) && items.every(isCustomerMembership)

export const checkCartHasBothMembershipAndRegularSku = (items) => {
  const cartHasOnlyMembership = checkCartHasOnlyMembershipSku(items)
  return (
    Array.isArray(items) &&
    items.some(isCustomerMembership) &&
    !cartHasOnlyMembership
  )
}

export const hasInvalidSkus = (address) => {
  const invalidQuantities = get(
    address,
    'extension_attributes.address_validation_data.validate_cart_data.invalid_qty'
  )
  return invalidQuantities && invalidQuantities.length > 0
    ? invalidQuantities
    : false
}

export const isValidAddress = (address) => {
  return get(
    address,
    'extension_attributes.address_validation_data.valid_to_ship'
  )
}

export const hasInvalidItems = (address) => {
  if (isValidAddress(address)) {
    return false
  }

  const hasInvalids = get(
    address,
    'extension_attributes.address_validation_data.validate_cart_data.invalid_qty.length'
  )
  if (hasInvalids && hasInvalids > 0) {
    return get(
      address,
      'extension_attributes.address_validation_data.validate_cart_data.invalid_qty'
    )
  }

  return false
}

export const numericFieldFilter = (text) => text.replace(/[^0-9]/gm, '')

export function findItem(arr, name) {
  for (let i = 0; i < arr.length; i++) {
    if (name === arr[i].name) {
      return arr[i]
    }
  }
  return {}
}
