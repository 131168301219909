export const ACTION_TYPE = {
  APPLY: 'apply',
  CLAIM: 'claim',
  USE_NOW: 'useNow',
  UNAPPLY: 'unapply',
}

export const ACTION_LABEL = {
  [ACTION_TYPE.APPLY]: 'APPLY NOW',
  [ACTION_TYPE.CLAIM]: 'CLAIM',
  [ACTION_TYPE.USE_NOW]: 'USE NOW',
}

export const VOUCHER_CATEGORY_TYPE = {
  MAIN: 'main',
  ADDONS: 'addons',
}

export const VOUCHER_CATEGORY_LABEL = {
  [VOUCHER_CATEGORY_TYPE.MAIN]: 'Main',
  [VOUCHER_CATEGORY_TYPE.ADDONS]: 'Add-On',
}

export const VOUCHER_DEFAULT_LOGO_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
}
