import { initialState } from './reducer'
import { createSelector } from 'reselect'

const selectModalsDomain = (state) => state.rnmodals || initialState

export const makeSelectShowDeliveryLocation = () =>
  createSelector(
    selectModalsDomain,
    (subState) => subState.showDeliveryLocation
  )

export const makeSelectAddedProduct = () =>
  createSelector(selectModalsDomain, (subState) => subState.addedProduct)

export const makeSelectShowProductDetail = () =>
  createSelector(selectModalsDomain, (subState) => subState.showProductDetail)

export const makeSelectShowLogin = () =>
  createSelector(selectModalsDomain, (subState) => subState.showLogin)

export const makeSelectShowForgotPassword = () =>
  createSelector(selectModalsDomain, (subState) => subState.showForgotPassword)

export const makeSelectAddUpdateAddress = () =>
  createSelector(
    selectModalsDomain,
    (subState) => subState.showAddUpdateAddress
  )

export const makeSelectInvalidCartItems = () =>
  createSelector(
    selectModalsDomain,
    (subState) => subState.showInvalidCartItems
  )

export const makeSelectRequestAddMembership = () =>
  createSelector(
    selectModalsDomain,
    (subState) => subState.showRequestAddMembership
  )

export const makeSelectCartNotification = () =>
  createSelector(
    selectModalsDomain,
    (subState) => subState.showCartNotification
  )

export const makeSelectRemoveAllCartItems = () =>
  createSelector(
    selectModalsDomain,
    (subState) => subState.showRemoveAllCartItems
  )

export const makeSelectRemoveCartItems = () =>
  createSelector(selectModalsDomain, (subState) => subState.showRemoveCartItems)

export const makeSelectShowAlert = () =>
  createSelector(selectModalsDomain, (subState) => subState.showAlert)

export const makeSelectIsSignUp = () =>
  createSelector(selectModalsDomain, (substate) => substate.isSignUp)

export const makeSelectShowMembership = () =>
  createSelector(selectModalsDomain, (subState) => subState.showMembership)

export const makeSelectShowShoppingList = () =>
  createSelector(selectModalsDomain, (substate) => substate.showShoppingList)

export const makeSelectFaqForm = () =>
  createSelector(selectModalsDomain, (substate) => substate.faqForm)

export const makeSelectPickUpConfirmation = () =>
  createSelector(
    selectModalsDomain,
    (substate) => substate.showPickUpConfirmation
  )

export const makeSelectShowAppPromotion = () =>
  createSelector(selectModalsDomain, (substate) => substate.showAppPromotion)

export const makeSelectShowSplashAd = () =>
  createSelector(selectModalsDomain, (substate) => substate.showSplashAd)

export const makeSelectTracking = () =>
  createSelector(selectModalsDomain, (substate) => substate.tracking)

export const makeSelectShowNewMCExpired = () =>
  createSelector(selectModalsDomain, (substate) => substate.showNewMCExpired)

export const makeSelectMembershipPayload = () =>
  createSelector(selectModalsDomain, (substate) => substate.membershipPayload)

export const makeSelectCurrentPopup = () =>
  createSelector(selectModalsDomain, (substate) => substate.currentPopup)

export const makeSelectShowNetPromotersScoreSurvey = () =>
  createSelector(
    selectModalsDomain,
    (substate) => substate.showNetPromotersScoreSurvey
  )

export const makeSelectNetPromotersScoreSurveyPayload = () =>
  createSelector(
    selectModalsDomain,
    (substate) => substate.netPromotersScorePayload
  )

export const makeSelectShowVoucherTermCondition = () =>
  createSelector(
    selectModalsDomain,
    (subState) => subState.showVoucherTermCondition
  )

export const makeSelectShowVoucherApply = () =>
  createSelector(selectModalsDomain, (subState) => subState.showVoucherApply)

export const makeSelectShowVoucherApplyNudge = () =>
  createSelector(
    selectModalsDomain,
    (subState) => subState.showVoucherApplyNudge
  )

export const makeSelectShowMembershipCard = () =>
  createSelector(selectModalsDomain, (subState) => subState.showMembershipCard)

export const showDeliveryAddressModal = () =>
  createSelector(selectModalsDomain, (substate) => substate.showDeliveryAddress)

export const makeSelectShowSubscribeSuccess = () =>
  createSelector(
    selectModalsDomain,
    (substate) => substate.showSubscribeSuccess
  )

export const makeSelectShowPaymentCard = () =>
  createSelector(selectModalsDomain, (substate) => substate.showPaymentCard)

export const makeSelectShowCardSaved = () =>
  createSelector(selectModalsDomain, (substate) => substate.showCardSaved)

export const makeSelectShowCardSavedSuccess = () =>
  createSelector(
    selectModalsDomain,
    (substate) => substate.showCardSavedSuccess
  )

export const makeSelectShowSupportModal = () =>
  createSelector(selectModalsDomain, (substate) => substate.showSupportMessage)

export const makeSelectShowRemoveCardSaved = () =>
  createSelector(selectModalsDomain, (substate) => substate.showRemoveCardSaved)

export const makeSelectShowCVCTooltip = () =>
  createSelector(selectModalsDomain, (substate) => substate.showCVCTooltip)

export const makeSelectShowAddCard = () =>
  createSelector(selectModalsDomain, (substate) => substate.showAddCard)

export const makeSelectShowAddMoreItemModal = () =>
  createSelector(
    selectModalsDomain,
    (substate) => substate.showAddMoreItemModal
  )

export default selectModalsDomain

export const makeSelectShowErrorMessageModal = () =>
  createSelector(selectModalsDomain, (substate) => substate.showErrorMessage)

export const makeSelectShowDropshipWarningMessageModal = () =>
  createSelector(
    selectModalsDomain,
    (substate) => substate.showDropshipWarningMessage
  )

export const makeSelectShowReorderModal = () =>
  createSelector(selectModalsDomain, (substate) => substate.showReorderModal)

export const makeSelectExpiredMCTooltip = () =>
  createSelector(selectModalsDomain, (substate) => substate.tooltipExpiredMC)

export const makeSelectReOrderDetails = () =>
  createSelector(selectModalsDomain, (substate) => substate.reorderDetails)
