import config from 'global-config'
import { getAccessToken, getStoreCode } from 'utils/request'
import { isMobile } from 'react-device-detect'
import { get } from 'lodash'
import { ACTION_TYPE } from 'constant/voucher'

const applyVoucherRequestHandler = ({
  voucherCodes,
  cartMaskId,
  selectedTimeslot,
  currentLocation,
}) => {
  const accessToken = getAccessToken()
  const storePickup = get(selectedTimeslot, 'storeAddress')
  const townshipId =
    get(currentLocation, 'extension_attributes.township_id') ||
    get(currentLocation, 'barangay.id')

  const request = {
    url: `${config.apiUrl}/guest-carts/${cartMaskId}/coupons/${voucherCodes[0]}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Device-Type': isMobile ? 'm-site' : 'desktop',
      sourcecode: get(storePickup, 'sourcecode', getStoreCode()) || '',
      townshipid: get(storePickup, 'townshipid', townshipId) || '',
    },
    method: 'PUT',
    data: '',
  }

  if (accessToken) {
    request.url = `${config.apiUrl}/carts/mine/multiple-coupons`
    request.headers['authorization'] = `Bearer ${accessToken}`
    request.method = 'POST'
    request.data = JSON.stringify({ coupon_codes: voucherCodes })
  }

  return request
}

const removeVoucherRequestHandler = ({ voucherCodes, cartMaskId }) => {
  const accessToken = getAccessToken()
  const request = {
    url: `${config.apiUrl}/guest-carts/${cartMaskId}/coupons/`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    data: '',
  }

  if (accessToken) {
    request.url = `${
      config.apiUrl
    }/carts/mine/multiple-coupons/${voucherCodes.join(',')}`
    request.headers['authorization'] = `Bearer ${accessToken}`
  }

  return request
}

export const voucherRequestHandlerMap = {
  [ACTION_TYPE.APPLY]: (params) =>
    applyVoucherRequestHandler({
      ...params,
    }),
  [ACTION_TYPE.UNAPPLY]: (params) =>
    removeVoucherRequestHandler({
      ...params,
    }),
}
