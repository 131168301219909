import { sha256 } from 'js-sha256'

export const EVENTS = {
  ViewContent: 'ViewContent',
  AddToCart: 'AddToCart',
  InitiateCheckout: 'InitiateCheckout',
}

export const EVENTS_ID = {
  ViewContent: 1,
  AddToCart: '5',
  InitiateCheckout: '6',
}

export const trackEventForTicktok = (event, payload, params = {}) => {
  window.ttq?.track(event, payload, params)
}

export const trackAddToCart = (productPayload) => {
  const [product] = productPayload.items
  const _brand = product?.extension_attributes?.brand
  const brand = Array.isArray(_brand) ? _brand[0] : _brand

  trackEventForTicktok(
    EVENTS.AddToCart,
    {
      contents: [
        {
          content_id: product.sku,
          content_name: product.name,
          //content_category: '',
          brand: brand,
          quantity: product.qty,
          price: product?.extension_attributes?.final_price,
        },
      ],
      content_type: 'product',
      value: product.qty * product?.extension_attributes?.final_price,
      currency: 'PHP',
    },
    { event_id: EVENTS_ID.AddToCart }
  )
}

export const trackViewContent = (product) => {
  const _brand = product?.extension_attributes?.brand
  const brand = Array.isArray(_brand) ? _brand[0] : _brand

  trackEventForTicktok(
    EVENTS.ViewContent,
    {
      contents: [
        {
          content_id: product.sku,
          content_name: product.name,
          //content_category: '',
          brand: brand,
          price: product?.extension_attributes?.final_price,
        },
      ],
      content_type: 'product',
      value: product?.extension_attributes?.final_price,
      currency: 'PHP',
    },
    { event_id: EVENTS_ID.ViewContent }
  )
}

export const trackInitiateCheckout = (products) => {
  const contents = []

  products.forEach((product) => {
    contents.push({
      content_id: product.item_id,
      content_type: 'product',
      content_name: product.name,
    })
  })

  const total = products.reduce(
    (accumulator, product) => accumulator + product.base_row_total,
    0
  )

  trackEventForTicktok(
    EVENTS.InitiateCheckout,
    {
      value: total.toFixed(2),
      currency: 'PHP',
      contents,
    },
    { event_id: EVENTS_ID.InitiateCheckout }
  )
}

export const identifyUser = (user) => {
  window?.ttq?.identify({
    email: sha256(user.email),
    external_id: sha256(user.id.toString()),
  })
}
